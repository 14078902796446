import { getUserId } from './localStorage';
import ReactGA from 'react-ga4';

export function initAnalytics() {
  let userId = getUserId();
  if (!process.env.CROWE_GA_ID) return;
  let analyticsId = process.env.CROWE_GA_ID;

  if (!analyticsId) return;

  ReactGA.initialize([
    {
      trackingId: analyticsId,
      gaOptions: { userId: userId },
    },
  ]);
}
