import { useState, useEffect } from 'react';
import { getBanners } from '../../../../services/banners';
import BannerSkeleton from 'common/components/Skeletons/BannerSkeleton';

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleBanners = async () => {
    setIsLoading(true);
    let data = await getBanners();
    setBanners(data.sort((a, b) => (a.fields.position > b.fields.position ? 1 : -1)));
    setIsLoading(false);
  };

  useEffect(() => {
    handleBanners();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
          <div className="banner__container">
            <BannerSkeleton />
          </div>
        </>
      ) : (
        banners &&
        banners.map(banner => (
          <div key={banner.sys.id} className="banner__container">
            {banner.fields.backgroundImage && (
              <img
                className="banner__background"
                src={banner.fields.backgroundImage.fields.file.url}
              />
            )}
            <a
              href={banner.fields.url}
              className="banner__card rounded"
              data-id="widget"
              target="_blank"
            >
              <div className="widget-card-cover rounded" />
              <div className="widget-card-content pb-0">
                <div
                  className={`banner__icon mb-5px ${
                    banner.fields.backgroundImage ? 'invisible' : ''
                  }`}
                >
                  <i className={`${banner.fields.icon} fa-1x text-white`} />
                </div>
                <h4
                  className={`banner__title ellipsis-sl ${
                    banner.fields.textColor === 'White' ? 'banner__title--white' : ''
                  }`}
                >
                  {banner.fields.title}
                </h4>
              </div>
              <div className="widget-card-content bottom pt-0">
                <p
                  className={`banner__paragraph ellipsis-sl ${
                    banner.fields.textColor === 'White' ? 'banner__title--white' : ''
                  }`}
                  data-id="widget-elm"
                  data-light-class="fs-12px text-black-transparent-7"
                  data-dark-class="fs-12px text-white-transparent-7"
                >
                  {banner.fields.description}
                </p>
              </div>
            </a>
          </div>
        ))
      )}
    </>
  );
};

export default Banners;
