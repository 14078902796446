const ContactCardSkeleton = () => {
  return (
    <div className="skeleton__member-card is-loading">
      <div className="skeleton__image" />
      <div className="skeleton__column skeleton__column--50">
        <h2></h2>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </div>
    </div>
  );
};

export default ContactCardSkeleton;
