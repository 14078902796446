import axios from 'axios';

export const getBanners = async () => {
  try {
    const res = await axios.get(process.env.CROWE_BASE_SERVER_URL + '/banners');
    return res.data.items;
  } catch (error) {
    return error;
  }
};
