const Pagination = ({ total, setPage, activePage }) => {
  const pages = Array.from(Array(total).keys());

  return (
    <div className="pager__container mt-10px">
      <div className="pager__inner">
        {activePage > 0 && (
          <i
            onClick={() => setPage(activePage - 1)}
            className="fa-solid fa-chevron-left pager__selector fs-12px"
          />
        )}
        {pages.map(page => (
          <div
            key={page}
            className={`pager__selector ${activePage === page ? 'pager__active' : ''}`}
            onClick={() => setPage(page)}
          >
            {page + 1}
          </div>
        ))}
        {activePage < pages.length - 1 && (
          <i
            onClick={() => setPage(activePage + 1)}
            className="fa-solid fa-chevron-right pager__selector fs-12px"
          />
        )}
      </div>
    </div>
  );
};

export default Pagination;
