import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { requiredPicture, isValidLinkedIn, fileSize } from 'common/utils/validations';
import Help from 'common/components/Registration/Help';
import { multiCapitalize } from 'common/utils/strings';

const ProfileInfo = ({ register, watch, errors, setValue, user, trigger }) => {
  const [preview, setPreview] = useState('');

  const { t } = useTranslation('userProfile');
  const { t: vt } = useTranslation('validations');

  const profileImg = watch('profile_photo_url');
  const resumeFile = watch('cv_url');

  const handlePreview = () => {
    if (typeof profileImg === 'string') {
      return setPreview(profileImg);
    }
    if (typeof profileImg === 'object' && profileImg && profileImg.length) {
      return setPreview(URL.createObjectURL(profileImg[0]));
    }
    return setPreview('');
  };

  useEffect(() => {
    handlePreview();
  }, [profileImg]);

  useEffect(() => {
    trigger();
  }, []);

  return (
    <div className="user__data">
      <h2 className="user__title mb-20px">{t('profileInformation')}</h2>
      <div className="user__inputs user__inputs--profile">
        <div className="user__column user__column--profile">
          <div className="user__input--photo">
            <div className="profile__img-container">
              {preview && <img src={preview} alt="Your profile picture" className="profile__img" />}
            </div>

            <div className="user__input--photo-action">
              <label className="profile__btn mb-0" htmlFor="profile_photo_url">
                {preview ? t('updatePhoto') : `${t('uploadPhoto')}*`}
              </label>
              <span className="input__error--photo">{errors.profile_photo_url?.message}</span>
            </div>

            <input
              {...register('profile_photo_url', {
                validate: {
                  requiredPicture: value => requiredPicture(value, vt),
                  fileSize: value => fileSize(value, vt, 'image'),
                },
              })}
              className="hidden"
              name="profile_photo_url"
              id="profile_photo_url"
              type="file"
              accept=".jpg, .jpeg, .png"
            />
          </div>
          <div className="user__input--cv">
            {resumeFile ? (
              <button
                className="user__btn user__btn--cv"
                onClick={() => {
                  setValue('cv_url', null);
                  trigger('cv_url');
                }}
              >
                {t('removeCV')}
              </button>
            ) : (
              <label className="user__btn user__btn--cv" htmlFor="cv_url">
                {t('uploadCV')}
              </label>
            )}
            <div className="user__input--cv-action">
              <input
                {...register('cv_url', {
                  validate: { fileSize: value => fileSize(value, vt, 'CV') },
                })}
                className="hidden"
                name="cv_url"
                id="cv_url"
                type="file"
                accept=".csv, .txt, .pdf, .doc, .docx"
              />
              <span className="input__error">{errors.cv_url?.message}</span>
              {resumeFile?.length ? (
                <div className="user__input--file">
                  <i className="fas fa-file-alt admin__icon--file" />
                  <p className="profile__file-name">
                    {resumeFile[0].name
                      ? resumeFile[0].name
                      : multiCapitalize(`${user.first_name} ${user.last_name}'s Resume`)}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="user__help mb-40px mt-30px">
            <Help text={t('sizeTip')} icon="fas fa-exclamation-circle" />
            <Help text={t('cvTip')} icon="fas fa-exclamation-circle" />
          </div>
        </div>
        <div className="user__column user__column--profile">
          <div className="user__input">
            <label>{t('linkedin')}</label>
            <input
              {...register('linkedin', {
                validate: { isValidLinkedIn: value => isValidLinkedIn(value, vt) },
              })}
              className={`form-control input__container ${
                errors.linkedin ? 'form__input--error' : ''
              }`}
              name="linkedin"
              placeholder={t('linkedinAccount')}
            />
            <span className="input__error">{errors.linkedin?.message}</span>
          </div>
          <div className="user__input user__input--bio">
            <label>{t('shortBio')}</label>
            <textarea
              {...register('bio')}
              className="form-control input__container "
              name="bio"
              placeholder={t('bioPlaceholder')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
