import axios from 'axios';

export const getNetworkRoles = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/network_roles`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getFirmRoles = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/firm_roles`);
    return res.data;
  } catch (error) {
    return error;
  }
};
