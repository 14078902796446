import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getSections } from 'services/navigation';
import Section from 'common/components/Sidebar/Section';

const Sidebar = ({ setOpen }) => {
  const [sections, setSections] = useState([]);

  const location = useLocation();
  const { pathname } = location;

  const handleSections = async () => {
    const data = await getSections();
    setSections(data.sort((a, b) => (a.fields.order > b.fields.order ? 1 : -1)));
  };

  useEffect(() => {
    handleSections();
  }, []);

  return (
    <>
      <div id="sidebar" className="app-sidebar pt-20px">
        <div className="app-sidebar-content">
          <div className="menu">
            {sections?.map(section => (
              <Section
                key={section.sys.id}
                section={section}
                pathname={pathname}
                setOpen={setOpen}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app-sidebar-mobile-backdrop" onClick={() => setOpen(false)}>
        <a href="#" data-dismiss="app-sidebar-mobile" className="stretched-link"></a>
      </div>
    </>
  );
};
export default Sidebar;
