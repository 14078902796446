import { useState, useEffect } from 'react';
import { useStore } from 'context/Store';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Help from 'common/components/Registration/Help';
import { languageOptions } from 'common/constants/foreignDesks';
import { Controller } from 'react-hook-form';
import { FOREIGN_DESK_REPRESENTATIVE_ID } from 'common/constants/foreignDesks';

const RolesInfo = ({ register, control, watch, trigger, errors }) => {
  const [isForeignDesk, setIsForeignDesk] = useState(false);

  const [state] = useStore();
  const {
    network_roles: storedNetworkRoles,
    firm_roles: storedFirmRoles,
    hasAdminPermission,
  } = state;

  const { t } = useTranslation('userProfile');

  const handleLabelClass = (isEditable, isVisible) => {
    if (isEditable && isVisible) return '';
    if ((!isEditable || !isVisible) && hasAdminPermission) return 'user__item--red';
    else return 'user__item--disabled';
  };

  const language = watch('lang');

  const FDRCheckbox = watch(`professionalData.networkRole.17`);

  useEffect(() => {
    trigger();
  }, []);

  useEffect(() => {
    setIsForeignDesk(FDRCheckbox);
  }, [FDRCheckbox]);

  return (
    <div className="user__data">
      <h2 className="user__title">{t('networkRole')}</h2>
      <div className="mb-10px">{t('networkComment1')}</div>
      <div className="fw-bold">{t('networkComment2')}</div>
      <div className="user__help">
        <Help
          text={t('roleHelp')}
          link="mailto:directory@crowe.org?Subject=MP, ILP registration"
          icon="fas fa-question-circle"
          linkText="directory@crowe.org."
        />
      </div>
      <div className="user__checklist">
        {!!storedNetworkRoles.length &&
          storedNetworkRoles.map(role => {
            if (role.visible || (!role.visible && hasAdminPermission)) {
              return (
                <div key={role.id}>
                  <div className="user__item">
                    <input
                      {...register(`professionalData.networkRole.${role.id}`, {
                        disabled: hasAdminPermission ? false : !role.editable,
                      })}
                      name={`professionalData.networkRole.${role.id}`}
                      type="checkbox"
                    />
                    <label className={handleLabelClass(role.editable, role.visible)}>
                      {role.name}
                    </label>
                  </div>

                  {role.id === FOREIGN_DESK_REPRESENTATIVE_ID && isForeignDesk && (
                    <div className="user__languages mb-20px">
                      <label>{t('selectLang')}</label>
                      <Controller
                        name="lang"
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field, ref }) => (
                          <Select
                            ref={ref}
                            options={languageOptions}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            isMulti
                            defaultValue={
                              language &&
                              language.split(',').map(lang => ({ label: lang, value: lang }))
                            }
                            onChange={val => {
                              field.onChange(val.map(option => option.value).join(','));
                            }}
                          />
                        )}
                      />
                      {!language && <span className="input__error">{t('required')}</span>}
                    </div>
                  )}
                </div>
              );
            }
          })}
      </div>

      <h2 className="user__title mb-30px mt-30px">{`${t('firmRole')} - ${t('firmRoleHeader')}`}</h2>
      <div className="user__checklist">
        {!!storedFirmRoles.length &&
          storedFirmRoles.map(role => {
            return (
              <div className="user__item" key={role.id}>
                <input
                  {...register(`professionalData.firmRole.${role.id}`)}
                  name={`professionalData.firmRole.${role.id}`}
                  type="checkbox"
                  className="user__item"
                />
                <label>{role.name}</label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RolesInfo;
