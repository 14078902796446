import { Link } from 'react-router-dom';
import Terms from 'common/components/Registration/Terms';
import logo from 'assets/crowe-logo.svg';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation('login');
  return (
    <div className="registration__container">
      <Link to="/login">
        <img className="registration__logo" src={logo} alt="Crowe" />
      </Link>
      <Terms />

      <Link to="/login" className="login__btn--privacy">
        {t('backToLogin')}
      </Link>
    </div>
  );
};

export default PrivacyPolicy;
