import { parseAndFormat } from 'common/utils/phone';
import _ from 'lodash';

const Office = ({ office, firm }) => {
  const phone_number = parseAndFormat(office.phone_number_country_code, office.phone_number);

  let url = firm.website_url;
  if (url) {
    if (!_.startsWith(url, 'http')) {
      url = 'http://' + url;
    }
  }

  return (
    <>
      {office.is_headquarters ? (
        <div className="offices__head">
          <h4 className="offices__title">Head Office: {office.city}</h4>
          <p className="office__detail">{firm.name}</p>
          <p className="office__detail">{office.address}</p>
          <p className="office__detail">{office.city}</p>
          <p className="office__detail">{office.postal_code}</p>
          <p className="office__detail mb-20px">{office.country}</p>
          {office.hours && (
            <div className="row-center mb-10px">
              <i className="far fa-clock" />
              <p className="my-0 fs-16px lh-sm mx-6px">{office.hours}</p>
            </div>
          )}
          {phone_number && (
            <div className="row-center mb-10px">
              <i className="fas fa-phone" />
              <p className="my-0 fs-16px lh-sm mx-6px">Office: {phone_number}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex-col py-20px offices__office">
          <h4>{office.city}</h4>
          <p className="m-0 fs-16px lh-sm">{firm.name}</p>
          <p className="m-0 fs-16px lh-sm">{office.address}</p>
          <p className="m-0 fs-16px lh-sm">{office.city}</p>
          <p className="m-0 fs-16px lh-sm">{office.postal_code}</p>
          <p className="m-0 fs-16px lh-sm mb-20px">{office.country}</p>
          {phone_number && (
            <div className="row-center">
              <i className="fas fa-phone" />
              <p className="my-0 fs-16px lh-sm mx-10px">Office: {phone_number}</p>
            </div>
          )}
          {office.email && (
            <div className="row-center">
              <i className="far fa-envelope" />
              <p className="my-0 fs-16px lh-sm mx-10px">{office.email}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Office;
