import { get } from '../common/api';
import { getMembers } from 'services/user';
import { getFirms } from 'services/firm';

export const getSearchItems = async (value, type) => {
  try {
    if (type === 'member') {
      const data = await getMembers(0, { name: value }, null, 20);
      return data.users;
    }
    if (type === 'firm') {
      const data = await getFirms(0, { name: value }, null, 20);
      return data.firms;
    }

    const res = await get(`/search?q=${value}&type=${type}`);
    return res.items;
  } catch (error) {
    return error;
  }
};
