import { useTranslation } from 'react-i18next';
import Pager from '../Pager/Pager';
import NoResults from 'common/components/NoResults/NoResults';
import { last } from 'lodash';

const DirectoryList = ({
  skeleton,
  recordComponent,
  isFetching,
  onSelectUser = {},
  recordCount,
  records,
  offset,
  pageSize,
  onSelectPage,
}) => {
  const { t } = useTranslation('directory');

  const RecordComponent = recordComponent;
  const Skeleton = skeleton;

  if (isFetching)
    return (
      <div className="directory__list">
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );

  return (
    <div>
      <div className="directory__list">
        {recordCount ? (
          records.map(record => (
            <RecordComponent
              key={record.id}
              lastRecord={last(records) === record}
              {...record}
              onSelectUser={onSelectUser}
            />
          ))
        ) : (
          <NoResults
            icon="icon-suitcase"
            title={t('noMatchesFound')}
            comment={t('tryDifferentFilters')}
          />
        )}
      </div>
      <Pager total={recordCount} offset={offset} limit={pageSize} onSelectPage={onSelectPage} />
    </div>
  );
};

export default DirectoryList;
