import { useTranslation } from 'react-i18next';

const KeyContacts = ({ title, link }) => {
  const { t } = useTranslation('home');

  return (
    <div className="widget-todolist rounded">
      <div className="widget-todolist-header widget__header">
        <i className="fas fa-users text-warning fs-16px" />
        <b className="widget__heading ellipsis-sl">{title}</b>
      </div>

      <div className="widget__key-contacts">
        <p className="widget__description widget__description--bold widget__description--big text-center">
          {t('widgets.question')}
        </p>
        <p className="widget__description widget__description  text-center mb-40px">
          {t('widgets.letUsKnow')} {t('widgets.emailUs')}
        </p>

        <div className="widget__btns--contacts">
          <a className="widget__btn widget__btn--contacts" href="mailto:help@crowe.org">
            {t('widgets.clientServices')}
          </a>
          <a className="widget__btn widget__btn--contacts" href="mailto:events@crowe.org">
            {t('widgets.events')}
          </a>
          <a className="widget__btn widget__btn--contacts" href="mailto:communications@crowe.org">
            {t('widgets.communications')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default KeyContacts;
