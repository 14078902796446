import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'context/Store';
import { homeSectionId, directorySectionId, eventsSectionId } from 'common/helpers/id';

const SinglePage = ({ section, handleActiveClass, pathname, setOpen, link }) => {
  const [_, dispatch] = useStore();

  const helper = [
    { field: section.fields.pages, path: '/pages/' },
    { field: section.fields.articleListPages, path: '/articles/' },
    { field: section.fields.resourceListPages, path: '/resources/' },
    { field: section.fields.commiteePages, path: '/committees/' },
  ];

  const handlePageType = () => {
    let pageType;
    if (pathname.includes('pages')) {
      pageType = 'page';
    }
    if (pathname.includes('articles')) {
      pageType = 'article';
    }
    if (pathname.includes('resource')) {
      pageType = 'resource';
    }
    if (pathname.includes('committees')) {
      pageType = 'committee';
    }
    dispatch({ type: 'SET_PAGE_TYPE', payload: pageType });
  };

  const handleRoutes = section => {
    const customSections = {
      [homeSectionId]: '/home',
      [directorySectionId]: '/directory',
      [eventsSectionId]: '/events',
    };

    if (Object.keys(customSections).includes(section.sys.id)) {
      return customSections[section.sys.id];
    }

    // Depending on section having the field in helper the prefix will be each path in helper + the id of the first item because it's not multiple, it's just one page.
    let prefix = helper
      .filter(item => item.field)
      .map(page => {
        return page.path + (page.field[0].fields.slug ?? `.${page.field[0].sys.id}`);
      });

    return `/${section.fields.slug ?? `.${section.sys.id}`}${prefix}`;
  };

  useEffect(() => {
    if (pathname.includes(section.fields.slug || section.sys.id)) {
      handlePageType();
    }
  }, [pathname]);

  return (
    <>
      {link ? (
        <a className="menu-link sidebar__section" href={link.fields.url} target="_blank">
          <i className={`sidebar__icon ${section.fields.icon}`} />
          <div className="menu-text">{section.fields.title}</div>
        </a>
      ) : (
        <Link
          to={handleRoutes(section)}
          className={`menu-link ${handleActiveClass()}`}
          onClick={() => setOpen(false)}
        >
          <i className={`sidebar__icon ${section.fields.icon}`} />

          <div className="menu-text">{section.fields.title}</div>
        </Link>
      )}
    </>
  );
};

export default SinglePage;
