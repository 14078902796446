const ResourceWidgetSkeleton = () => {
  return (
    <div className="skeleton__widget is-loading">
      <div className="content">
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </div>
    </div>
  );
};

export default ResourceWidgetSkeleton;
