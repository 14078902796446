import { useState, useEffect } from 'react';
import { useStore } from 'context/Store';
import Page from 'common/components/Layout/Page';
import { getPageById } from 'services/pages';
import { Link } from 'react-router-dom';
import ArticleListPage from 'core/Layout/ArticleListPage';
import CommitteePage from 'core/Layout/CommitteePage';
import ResourceListPage from 'core/Layout/ResourceListPage';
import { useGetSection } from 'hooks/useGetSection';
import { eventsSectionSlug } from 'common/helpers/id';
import { upcomingEventsPath, pastEventsPath } from 'common/helpers/path';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

const PageList = () => {
  const [subPages, setSubPages] = useState([]);
  const [isSubPage, setIsSubPage] = useState(false);

  const [state] = useStore();

  const { t } = useTranslation('events');
  const location = useLocation();

  const { section, slug, subSlug } = useParams();

  const { data } = useGetSection(section, slug);

  const { pages, resourceListPages, articleListPages, committeePages } =
    data?.items?.[0]?.fields ?? data?.fields ?? {};

  const { pageType } = state;

  // HELPER
  const pagesList = [
    { list: articleListPages },
    { list: committeePages },
    { list: pages },
    { list: resourceListPages },
  ];

  const handleSubPages = async () => {
    try {
      const data = await getPageById(slug);
      if (data?.length && data[0].fields && data[0].fields.subPages) {
        return setSubPages(data[0].fields.subPages);
      }
      if (!isSubPage || !data?.[0]?.fields.subPages) {
        setIsSubPage(false);
        return setSubPages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLayout = () => {
    const helper = {
      page: <Page slug={subSlug ?? slug} />,
      article: <ArticleListPage location={location} />,
      committee: <CommitteePage />,
      resource: <ResourceListPage location={location} />,
    };
    return helper[pageType];
  };

  const handleLink = page => {
    if (page.fields.title === 'Upcoming Events') {
      return upcomingEventsPath;
    }
    if (page.fields.title === 'Past Events') {
      return pastEventsPath;
    } else {
      return `/${section}/pages/${page.fields.slug ?? `.${page.sys.id}`}`;
    }
  };

  useEffect(() => {
    handleSubPages();
  }, [slug]);

  useEffect(() => {
    setIsSubPage(!!subSlug);
  }, [slug, subSlug]);

  return (
    <div>
      <div className="page__tabs page__tabs--responsive">
        {section === eventsSectionSlug && (
          <>
            <Link
              key={'upcoming events'}
              to={upcomingEventsPath}
              className={`page__tab`}
              onClick={() => {
                setIsSubPage(false);
              }}
            >
              {t('upcomingEvents')}
            </Link>
            <Link
              key={'past events'}
              to={pastEventsPath}
              className={`page__tab`}
              onClick={() => {
                setIsSubPage(false);
              }}
            >
              {t('pastEvents')}
            </Link>
          </>
        )}
        {pagesList.map(pageList =>
          pageList.list?.map(page => (
            <Link
              key={page.sys.id}
              to={handleLink(page)}
              className={`page__tab ${
                slug === page.fields.slug || slug.includes(page.sys.id) ? 'page__tab--active' : ''
              }`}
              onClick={() => {
                setIsSubPage(false);
              }}
            >
              {page.fields.title}
            </Link>
          ))
        )}
      </div>

      {!!subPages?.length && (
        <div className="page__sub-tabs">
          {subPages.map(subPage => (
            <Link
              key={subPage.sys.id}
              to={`/${section}/pages/${slug}/${subPage.fields.slug ?? `.${subPage.sys.id}`}`}
              className={`page__sub-tab ${
                subSlug && (subPage.fields.slug === subSlug || subSlug.includes(subPage.sys.id))
                  ? 'page__sub-tab--active'
                  : ''
              }`}
              onClick={() => {
                setIsSubPage(true);
              }}
            >
              {subPage.fields.title}
            </Link>
          ))}
        </div>
      )}
      {handleLayout()}
    </div>
  );
};

export default PageList;
