const BouncingButton = ({ className, onClick, text, loading, type, disabled, children }) => {
  return (
    <button className={className} disabled={disabled} onClick={onClick} type={type}>
      <span>{children}</span>
      {loading && (
        <div className="button__ellipsis">
          <span className="button__loading"></span>
        </div>
      )}
    </button>
  );
};

export default BouncingButton;
