import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'context/Store';

const UserDropdown = ({
  id,
  email,
  status,
  onSendPasswordResetEmail,
  onSetRolePrivilege,
  reject,
  onDeactivate,
  onApprove,
  staffRole,
  setSelectedUserId,
  selectedUserId,
}) => {
  const [state] = useStore();
  const { hasAdminPermission, hasFirmAdminPermission } = state;

  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation('global');

  const isAdmin = hasAdminPermission || hasFirmAdminPermission;

  const handleClick = () => {
    setSelectedUserId(id);
    if (selectedUserId !== id) {
      setIsOpen(true);
    }
    if (selectedUserId == id) {
      setIsOpen(!isOpen);
    }
  };

  const showGrantPrivilege = isAdmin && staffRole === 'MEMBER' && status === 'APPROVED';
  const showRevokePrivilege = isAdmin && staffRole !== 'MEMBER' && status === 'APPROVED';

  return (
    <div className="staff__options">
      <i className="fas fa-ellipsis-v staff__options p-10px" onClick={handleClick} />
      {isOpen && id === selectedUserId && (
        <div className="options__container rounded">
          {showGrantPrivilege && (
            <div
              className="options__option"
              onClick={() => onSetRolePrivilege(id, email, 'FIRM_ADMIN')}
            >
              <i className="fas fa-key" />
              <span>{t('grantFirmPrivileges')}</span>
            </div>
          )}

          {showRevokePrivilege && (
            <div
              className="options__option"
              onClick={() => onSetRolePrivilege(id, email, 'MEMBER')}
            >
              <i className="fas fa-key" />
              <span>{t('revokeFirmPrivileges')}</span>
            </div>
          )}

          {isAdmin && (
            <div className="options__option" onClick={() => onSendPasswordResetEmail(id, email)}>
              <i className="fas fa-lock" />
              <span>{t('sendPasswordReset')}</span>
            </div>
          )}
          {isAdmin && (
            <Link to={`/users/${id}/edit`} className="options__option">
              <i className="fas fa-pencil-alt" />
              <span>{t('editProfile')}</span>
            </Link>
          )}
          {reject && isAdmin && (
            <div
              className="options__option"
              onClick={() => {
                reject(id);
              }}
            >
              <i className="fa fa-times" />
              <span>{t('rejectRequest')}</span>
            </div>
          )}
          {onDeactivate && status == 'APPROVED' && isAdmin && (
            <div
              className="options__option"
              onClick={() => {
                onDeactivate(id, email);
              }}
            >
              <i className="fas fa-ban" />

              <span>{t('deactivateUser')}</span>
            </div>
          )}
          {onApprove && status !== 'APPROVED' && hasAdminPermission && (
            <div
              className="options__option"
              onClick={() => {
                onApprove(id);
              }}
            >
              <i className="fa-regular fa-circle-check" />

              <span>{t('activateUser')}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
