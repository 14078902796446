import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postSocial } from 'services/social';
import { useStore } from 'context/Store';
import { socialsPath } from 'common/helpers/path';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from 'common/constants/toast';
import BouncingButton from 'common/components/Button/BouncingButton';

const PostForm = () => {
  const [state] = useStore();

  const [values, setValues] = useState({ snapshot: '', title: '', description: '' });
  const [errors, setErrors] = useState({ snapshot: false, title: false, description: false });
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const { currentUser: user } = state;

  const { t } = useTranslation('social');
  const { t: gt } = useTranslation('global');

  const handleChange = e => {
    setErrors({ ...errors, [e.target.name]: false });
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleErrors = e => {
    setErrors({ ...errors, [e.target.name]: !e.target.value });
  };

  const handleImg = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setValues({ ...values, snapshot: file });
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await postSocial({ ...values, ...user });
      toast.success(t('submitted'), TOAST_CONFIG);
      clearForm();
    } catch (error) {
      toast.error(gt('errorMessage'), TOAST_CONFIG);
    }
    setLoading(false);
  };

  const clearForm = () => {
    setValues({ snapshot: '', title: '', description: '' });
    setImagePreviewUrl('');
  };

  return (
    <div className="layout__container">
      <h1 className="layout__title">{t('newPost')}</h1>
      <form className="socials__form" onSubmit={handleSubmit}>
        <div className="socials__comment-container">
          <p className="socials__comment socials__comment--bold">{t('toCreate')}</p>
          <p className="socials__comment">{t('pleaseComplete')}</p>
          <p className="socials__comment">{t('itMayTake')}</p>
        </div>
        <div className="socials__label-container">
          <label className="socials__label" htmlFor="snapshot">
            <img
              htmlFor="snapshot"
              src={imagePreviewUrl}
              className={errors.snapshot ? 'socials__input--error' : 'socials__img'}
            />
            {!imagePreviewUrl && <p className="socials__click">{t('clickTo')}</p>}
            <input
              id="snapshot"
              name="snapshot"
              className="socials__input--img"
              type="file"
              accept="image/*"
              onChange={handleImg}
            />
            <i className="fas fa-camera socials__icon--camera" />
            {errors.snapshot && <span className="socials__error">{t('required')}</span>}
          </label>
          <div className="socials__crop">
            <span className="fw-bold">{t('cropping1')}</span>
            <span>{t('cropping2')}</span>
            <span>{t('cropping3')}</span>
          </div>
          <div className="socials__crop mt-20px">
            <span className="fw-bold">{t('morePhotos1')}</span>
            <span>{t('morePhotos2')}</span>
            <span>{t('morePhotos3')}</span>
            <a
              href="mailto:mods@crowe.org?subject=Social Post&body=Title:%0D%0ADescription:"
              className="socials__btn--cancel socials__btn--photos"
            >
              {t('photosBtn')}
            </a>
          </div>
        </div>
        <div className="socials__fields">
          <div className="socials__field">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              name="title"
              className={`form-control socials__input ${
                errors.title ? 'socials__input--error' : ''
              }`}
              onChange={handleChange}
              onBlur={handleErrors}
              value={values.title}
            />
            {errors.title && <span className="socials__error">{t('required')}</span>}
          </div>
          <div className="socials__field">
            <label htmlFor="description">{t('description')}</label>
            <textarea
              type="text"
              name="description"
              className={`form-control socials__input socials__input--textarea ${
                errors.description ? 'socials__input--error' : ''
              }`}
              onChange={handleChange}
              onBlur={handleErrors}
              value={values.description}
            />
            {errors.description && <span className="socials__error">{t('required')}</span>}
          </div>

          <div className="socials__actions">
            <Link to={socialsPath} className="socials__btn socials__btn--cancel">
              {t('cancel')}
            </Link>
            <BouncingButton
              className={`socials__btn ${
                !values.snapshot ||
                !values.title ||
                !values.description ||
                errors.title ||
                errors.description ||
                loading
                  ? 'socials__btn--disabled'
                  : 'socials__btn--active'
              }`}
              loading={loading}
              type="submit"
              disabled={
                !values.snapshot ||
                !values.title ||
                !values.description ||
                errors.title ||
                errors.description ||
                loading
              }
            >
              {loading ? t('submitting') : t('submit')}
            </BouncingButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
