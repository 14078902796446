import { useState, useEffect } from 'react';
import AccountInfoForm from 'common/components/Registration/AccountInfoForm';
import ProfileInfoForm from 'common/components/Registration/ProfileInfoForm';
import ServiceProviderInfoForm from 'common/components/Registration/ServiceProviderInfoForm';
import { registration } from 'services/registration';
import { Tabs, Tab } from 'react-toolbox/lib/tabs';
import FontIcon from 'react-toolbox/lib/font_icon';
import { setCookie } from 'common/utils/localStorage';
import { useTranslation } from 'react-i18next';
import logo from 'assets/crowe-logo.svg';
import Terms from 'common/components/Registration/Terms';
import Help from 'common/components/Registration/Help';
import { Link } from 'react-router-dom';
import { useStore } from 'context/Store';
import { useForm } from 'react-hook-form';
import { REGISTRATION_DEFAULT_VALUES } from 'common/constants/forms';

const Register = () => {
  const { t } = useTranslation('registration');

  const { register, control, handleSubmit, trigger, formState, watch, setValue } = useForm({
    mode: 'all',
    defaultValues: REGISTRATION_DEFAULT_VALUES,
  });

  const { errors, isValid } = formState;
  const [submitError, setSubmitError] = useState('');
  const [tab, setTab] = useState(0);
  const [isForeignDesk, setIsForeignDesk] = useState(false);

  const [state] = useStore();
  const { countries, cities } = state;

  const handleTab = tab => {
    setTab(tab);
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleTerms = () => {
    setCookie('authTerms', t('iAccept'), 365);
    handleTab(1);
  };

  const selectedCountry = watch('professionalData.country');
  const selectedCity = watch('professionalData.city');
  const countryOptions = Object.keys(countries).map(country => ({
    label: country,
    value: country,
  }));

  const cityOptions = selectedCountry
    ? countries[selectedCountry].map(city => ({
        label: city,
        value: city,
      }))
    : [];

  const firmOptions = selectedCity
    ? cities[selectedCity].map(firm => ({
        label: firm,
        value: firm,
      }))
    : [];

  const handleRegistration = async params => {
    try {
      await registration(params);
      return (window.location = '/login?registerSuccess=true');
    } catch (error) {
      setSubmitError(error.message);
    }
  };

  useEffect(() => {
    document.title = 'Crowe Global - Registration';
  }, []);

  return (
    <div className="registration__container">
      <Link to="/login">
        <img className="registration__logo" src={logo} alt="Crowe" />
      </Link>
      <h1 className="registration__title">{t('title')}</h1>
      <form
        className="registration__widget rounded"
        onSubmit={handleSubmit(handleRegistration)}
        noValidate
      >
        {tab !== 0 && (
          <div className="registration__steps">
            <div className={`registration__step ${tab === 1 ? 'registration__step--active' : ''}`}>
              <h2 className="step__title">{t('step1')}</h2>
              <p className="step__description">{t('accountInformation')}</p>
            </div>
            <div className={`registration__step ${tab === 2 ? 'registration__step--active' : ''}`}>
              <h2 className="step__title">{t('step2')}</h2>
              <p className="step__description">{t('profileInformation')}</p>
            </div>
            <div className={`registration__step ${tab === 3 ? 'registration__step--active' : ''}`}>
              <h2 className="step__title">{t('step3')}</h2>
              <p className="step__description">{t('serviceProviderDetails')}</p>
            </div>
          </div>
        )}

        <Tabs index={tab} className="registration__tabs">
          <Tab>
            <Terms handleTerms={handleTerms} />
          </Tab>

          <Tab>
            <div className="account__container">
              <div className="account__warning">
                <h3 className="account__important">{t('warning')}</h3>
                <div className="account__info-container">
                  <Help
                    text={t('forInformation')}
                    link="mailto:contactus@crowe.org"
                    icon="fas fa-info-circle"
                  />
                </div>
              </div>
              <AccountInfoForm
                register={register}
                control={control}
                watch={watch}
                errors={errors}
                trigger={trigger}
                handleTab={handleTab}
                t={t}
                countries={countryOptions}
                cities={cityOptions}
                firms={firmOptions}
              />
            </div>
          </Tab>

          <Tab>
            <div className="profile__container">
              <ProfileInfoForm
                handleTab={handleTab}
                isForeignDesk={isForeignDesk}
                setIsForeignDesk={setIsForeignDesk}
                register={register}
                control={control}
                trigger={trigger}
                errors={errors}
                watch={watch}
                setValue={setValue}
              />
            </div>
          </Tab>

          <Tab>
            <ServiceProviderInfoForm
              register={register}
              watch={watch}
              handleTab={handleTab}
              isValid={isValid}
              t={t}
            />
          </Tab>
        </Tabs>
        <div>
          {submitError && (
            <p className="registration__error">
              <FontIcon value="warning" /> {submitError}
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default Register;
