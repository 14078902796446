import { useState, useEffect } from 'react';
import Page from 'common/components/Sidebar/Page';
import SinglePage from 'common/components/Sidebar/SinglePage';
import { directorySectionId, eventsSectionId } from 'common/helpers/id';
import customPages from 'common/components/Sidebar/customPages';

const Section = ({ section, pathname, setOpen }) => {
  const [isMultiple, setIsMultiple] = useState(false);

  const {
    pages,
    committeePages,
    articleListPages,
    resourceListPages,
    resource: link,
    slug,
  } = section.fields;

  const { id } = section.sys;

  const handleContent = () => {
    setIsMultiple(
      (pages?.length || 0) +
        (committeePages?.length || 0) +
        (resourceListPages?.length || 0) +
        (articleListPages?.length || 0) >
        1 ||
        section.sys.id === directorySectionId ||
        section.sys.id === eventsSectionId
    );
  };

  const handleActiveClass = () => {
    let sectionSlug = pathname.split('/');
    sectionSlug = sectionSlug[1];
    if (sectionSlug === slug || sectionSlug.includes(id)) {
      return 'sidebar__section--active';
    }
    return 'sidebar__section';
  };

  // HELPER
  const pagesList = [
    { list: articleListPages, type: 'article' },
    { list: committeePages, type: 'committee' },
    { list: pages, type: 'page' },
    { list: resourceListPages, type: 'resource' },
  ];

  useEffect(() => {
    handleContent();
  }, [section]);

  return (
    <div className="menu-item has-sub">
      {isMultiple ? (
        <>
          <a
            className={`menu-link ${
              pathname.includes(slug) ? 'sidebar__section--active' : 'sidebar__section'
            }`}
          >
            <i className={`sidebar__icon ${section.fields.icon}`} />
            <div className="menu-text">{section.fields.title}</div>
            <div className="menu-caret" />
          </a>
          <div className="menu-submenu">
            {section.sys.id === directorySectionId &&
              customPages.GDPages.map(page => (
                <Page
                  key={page.sys.id}
                  page={page}
                  section={section}
                  pathname={pathname}
                  setOpen={setOpen}
                />
              ))}
            {section.sys.id === eventsSectionId &&
              customPages.eventsPages.map(page => (
                <Page
                  key={page.sys.id}
                  page={page}
                  section={section}
                  pathname={pathname}
                  setOpen={setOpen}
                />
              ))}
            {pagesList.map(pageList =>
              pageList.list?.map(page => (
                <Page
                  key={page.sys.id}
                  page={page}
                  section={section}
                  pathname={pathname}
                  pageType={pageList.type}
                  setOpen={setOpen}
                />
              ))
            )}
          </div>
        </>
      ) : (
        <SinglePage
          section={section}
          handleActiveClass={handleActiveClass}
          pathname={pathname}
          setOpen={setOpen}
          link={link}
        />
      )}
    </div>
  );
};

export default Section;
