const BannerSkeleton = () => {
  return (
    <div className="skeleton__banner is-loading">
      <div className="content">
        <h2></h2>
        <p></p>
      </div>
    </div>
  );
};

export default BannerSkeleton;
