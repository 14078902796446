import Modal from 'common/components/Modal/Modal';
import Terms from 'common/components/Registration/Terms';
import logo from 'assets/crowe-logo.svg';
import { useTranslation } from 'react-i18next';
import { useStore } from 'context/Store';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from 'common/constants/toast';
import { logout } from 'services/login';
import { updateTerms } from 'services/terms';

const TosModal = ({ setOpenTos }) => {
  const [state] = useStore();

  const { currentUser } = state;

  const { t } = useTranslation('cookies');
  const { t: gt } = useTranslation('global');

  const handleTos = async status => {
    try {
      if (status) {
        await updateTerms(currentUser.id);
        toast.success(t('youAccepted'), TOAST_CONFIG);
      } else {
        toast.warn(t('notAccepted'), TOAST_CONFIG);
        logout();
      }
      setOpenTos(false);
    } catch (error) {
      toast.error(gt('errorMessage'), TOAST_CONFIG);
    }
  };

  return (
    <Modal>
      <div className="tos__container">
        <img className="registration__logo" src={logo} alt="Crowe" />
        <p className="tos__comment">{t('weUpdated')}</p>
        <Terms />
        <div className="cookies__actions">
          <button
            className="cookies__action cookies__action--decline"
            onClick={() => handleTos(false)}
          >
            {t('Decline')}
          </button>
          <button
            className="cookies__action cookies__action--accept"
            onClick={() => handleTos(true)}
          >
            {t('Accept')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TosModal;
