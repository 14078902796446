import { useMemo } from 'react';
import { formatDateWithTimezone } from 'common/helpers/date';
import moment from 'moment';
import pastImg from 'assets/past-events.jpg';
import upcomingImg from 'assets/events-placeholder.jpg';
import { useTranslation } from 'react-i18next';

const Event = ({ event }) => {
  const { t } = useTranslation('events');

  const { start, title, venues, format, timezone } = event;
  const today = new Date();

  const { href: link } = event._links.summary;

  const date = new Date(start).toISOString().split('T')[0];
  const virtualFormat = 'Virtual';

  const image = useMemo(() => {
    const imageField = event.customFields?.filter(field => field.name === 'Event Header Image URL');
    if (imageField?.length) {
      return imageField[0].value[0];
    }
    return new Date(date) < today ? pastImg : upcomingImg;
  }, [event.customFields]);

  const handleFormat = () => {
    if (format === virtualFormat) {
      return t('virtual');
    } else if (venues?.length) {
      return venues[0].address.city + ', ' + venues[0].address.country;
    } else {
      return t('inPerson');
    }
  };

  const handleDateFormat = () => {
    const isVirtual = format === 'Virtual';
    const dateFormat = isVirtual ? 'MMMM D, YYYY, h:mm a' : 'MMMM D, YYYY';
    const city = isVirtual ? timezone.split('/')[1]?.replace('_', ' ') : '';
    return `${formatDateWithTimezone(start, timezone, dateFormat)} ${isVirtual ? `(${city})` : ''}`;
  };

  return (
    <a href={link} target="_blank" className="event">
      <div className="event__container">
        <img className="event__img" src={image} />
        <h1 className="event__title">{title}</h1>
        <div>
          <div className="event__data">
            <i className="fas fa-calendar-alt event__icon" />
            <p className="event__date">{handleDateFormat()}</p>
          </div>
          <div className="event__data">
            <i
              className={`event__icon fa-solid ${
                format === virtualFormat
                  ? 'fa-laptop event__icon--computer'
                  : 'fa-location-dot event__icon--location'
              } `}
            />

            <p className="event__address">{handleFormat()}</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Event;
