import Office from './Office/Office';
import OfficeForm from './Office/OfficeForm';
import { filter, find } from 'lodash';

const Offices = ({ offices, firm, editMode }) => {
  const headOffice = find(offices, o => o.is_headquarters);
  const branchOffices = filter(offices, o => !o.is_headquarters);

  return (
    <div className={`office__container ${editMode ? 'p-0' : ''}`}>
      {!editMode && headOffice && <Office firm={firm} office={headOffice} />}

      {editMode && headOffice && (
        <OfficeForm office={headOffice} form={`editOffice-${headOffice.id}`} />
      )}

      {!editMode && !!branchOffices.length && (
        <>
          <h4 className={`offices__locations offices__title ${headOffice ? 'border-top' : 'p-0'}`}>
            Locations
          </h4>
          <div className="offices__container">
            {branchOffices.map((office, index) => {
              return <Office firm={firm} office={office} key={index} />;
            })}
          </div>
        </>
      )}

      {editMode &&
        !!branchOffices.length &&
        branchOffices.map((office, index) => {
          return <OfficeForm office={office} key={index} form={`editOffice-${office.id}`} />;
        })}
    </div>
  );
};

export default Offices;
