import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateOffice } from 'services/office';
import Flag from 'common/components/Flag/Flag';
import { useStore } from 'context/Store';
import { useForm } from 'react-hook-form';
import {
  required,
  email as isEmail,
  validCountryCode,
  containsOnlyNumbers,
} from 'common/utils/validations';

const OfficeForm = ({ office }) => {
  const { t } = useTranslation('admin');
  const { t: vt } = useTranslation('validations');

  const { register, handleSubmit, trigger, formState } = useForm({
    mode: 'all',
    defaultValues: office,
  });
  const { errors, isValid } = formState;

  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [state] = useStore();
  const { hasFirmAdminPermission } = state;

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleUpdate = async data => {
    try {
      setSubmitting(true);
      await updateOffice(office.id, data);
      window.location.reload();
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    trigger();
  }, []);

  return (
    <form className="admin__tab-container" onSubmit={handleSubmit(handleUpdate)} noValidate>
      <div className="admin__office">
        <div className="office__flag">
          <div className="card__flag">
            <Flag country={office.country_code} />
          </div>
          <h2 className="office__country mx-10px">{`${office.city} ${t('office')}`}</h2>
        </div>

        {office.is_headquarters && <p className="office__head-office">{t('headOffice')}:</p>}

        <div className="admin__inputs">
          {!editing && (
            <>
              <p className="office__detail">
                {t('address')}: {office.address}
              </p>
              <p className="office__detail">
                {t('stateOrProvince')}: {office.state_or_province}
              </p>
              <p className="office__detail">City: {office.city}</p>
              <p className="office__detail">
                {t('ps')}: {office.postal_code}
              </p>
              <p className="office__detail">
                {t('country')}: {office.country}
              </p>
              <p className="office__detail">
                {t('phone')}: {`+${office.phone_number_country_code} ${office.phone_number}`}
              </p>
              <p className="office__detail">
                {t('email')}:
                <a className="office__detail office__detail--email" href={`mailto:${office.email}`}>
                  {office.email}
                </a>
              </p>
              <p className="office__detail">Hours: {office.hours}</p>
              {hasFirmAdminPermission && (
                <button className="office__btn" onClick={toggleEdit}>
                  {t('editOffice')}
                </button>
              )}
            </>
          )}
          {editing && (
            <div>
              <div>
                <label>{t('address')}</label>
                <input
                  {...register('address', { validate: { required: value => required(value, vt) } })}
                  className={`form-control input__container mb-4px ${
                    errors.address ? 'form__input--error' : ''
                  }`}
                  name="address"
                  placeholder={t('address')}
                />

                <p className="office__tip">
                  <span className="input__error">{errors.address?.message} </span>
                  {t('addressOnly')}
                </p>
              </div>
              <label>{t('stateOrProvince')}</label>

              <div>
                <input
                  disabled
                  {...register('state_or_province', {
                    validate: { required: value => required(value, vt) },
                  })}
                  className={`form-control input__container ${
                    errors.state_or_province ? 'form__input--error' : ''
                  }`}
                  name="state_or_province"
                  placeholder="State or Province"
                />
                <span className="input__error">{errors.state_or_province?.message}</span>
              </div>

              <div>
                <label>{t('city')}</label>
                <input
                  disabled
                  {...register('city', { validate: { required: value => required(value, vt) } })}
                  className={`form-control input__container ${
                    errors.city ? 'form__input--error' : ''
                  }`}
                  name="city"
                  placeholder={t('city')}
                />
                <span className="input__error">{errors.city?.message}</span>
              </div>

              <div>
                <label>{t('ps')}</label>
                <input
                  {...register('postal_code', {
                    validate: { required: value => required(value, vt) },
                  })}
                  className={`form-control input__container ${
                    errors.postal_code ? 'form__input--error' : ''
                  }`}
                  name="postal_code"
                  placeholder={t('ps')}
                />
                <span className="input__error">{errors.postal_code?.message}</span>
              </div>

              <div className="office__phone">
                <div className="office__input--code">
                  <label>{t('countryCode')}</label>
                  <input
                    {...register('phone_number_country_code', {
                      validate: {
                        required: value => required(value, vt),
                        isCountryCode: code => validCountryCode(code, vt),
                      },
                    })}
                    className={`form-control input__container ${
                      errors.phone_number_country_code ? 'form__input--error' : ''
                    }`}
                    name="phone_number_country_code"
                    placeholder="Country Code"
                  />
                  <span className="input__error">{errors.phone_number_country_code?.message}</span>
                </div>

                <div className="office__input--phone">
                  <label>{t('phoneNumber')}</label>
                  <input
                    {...register('phone_number', {
                      validate: {
                        required: value => required(value, vt),
                        containsOnlyNumbers: phone => containsOnlyNumbers(phone, vt),
                      },
                    })}
                    className={`form-control input__container ${
                      errors.phone_number ? 'form__input--error' : ''
                    }`}
                    name="phone_number"
                    placeholder="Phone Number"
                  />
                  <span className="input__error">{errors.phone_number?.message}</span>
                </div>
              </div>

              <div>
                <label>{t('email')}</label>
                <input
                  {...register('email', {
                    validate: {
                      required: value => required(value, vt),
                      isEmail: value => isEmail(value, vt),
                    },
                  })}
                  className={`form-control input__container ${
                    errors.email ? 'form__input--error' : ''
                  }`}
                  name="email"
                  placeholder={t('email')}
                />
                <span className="input__error">{errors.email?.message}</span>
              </div>

              <div>
                <label>{t('officeHours')}</label>
                <input
                  {...register('hours', { validate: { required: value => required(value, vt) } })}
                  className={`form-control input__container ${
                    errors.hours ? 'form__input--error' : ''
                  }`}
                  name="hours"
                  placeholder={t('officeHours')}
                />
                <span className="input__error">{errors.hours?.message}</span>
              </div>

              <button
                type="submit"
                disabled={!isValid || submitting}
                className={`office__btn ${!isValid || submitting ? 'office__btn--disabled' : ''}`}
              >
                {submitting ? t('savingChanges') : t('saveChanges')}
              </button>
              <button className="office__btn office__btn--disabled" onClick={toggleEdit}>
                {t('cancel')}
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default OfficeForm;
