import ContactCard from 'common/components/FirmDetail/ContactCard';

const Committee = ({ committee, id, isOpen, setIsOpen, isUnique }) => {
  const { title, people } = committee.fields;

  const handleOpening = () => {
    if (isOpen && id === isOpen) {
      return setIsOpen('');
    }
    setIsOpen(id);
  };

  return (
    <>
      {isUnique ? (
        <div className="accordion-item accordion__item">
          <h2 className="accordion-header accordion__header">
            <button className="accordion-button committees__subtitle committees__subtitle--active">
              {title}
            </button>
          </h2>
          <div className="accordion-collapse">
            <div className="accordion-body">
              {!!people?.length &&
                people.map(person => (
                  <div className="committees__user" key={person.sys.id}>
                    <ContactCard
                      id={person.fields.portalUserId}
                      customRole={person.fields.customRoleName}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="accordion-item accordion__item">
          <h2 className="accordion-header accordion__header" id={`heading${id}`}>
            <button
              className={`accordion-button committees__subtitle ${
                isOpen === id ? 'committees__subtitle--active' : ''
              }`}
              type="button"
              data-bs-toggle={'collapse'}
              data-bs-target={`#collapse${id}`}
              aria-expanded="true"
              aria-controls={`collapse${id}`}
              onClick={handleOpening}
            >
              {title}
              <i
                className={`icon-arrowDown accordion__arrow ${
                  isOpen === id ? 'accordion__arrow--up' : ''
                }`}
              />
            </button>
          </h2>
          <div
            id={`collapse${id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`collapse${id}`}
            data-bs-parent="#committeesAccordion"
          >
            <div className="accordion-body">
              {!!people?.length &&
                people.map(person => (
                  <div className="committees__user" key={person.fields.portalUserId}>
                    <ContactCard
                      id={person.fields.portalUserId}
                      customRole={person.fields.customRoleName}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Committee;
