import _ from 'lodash';

export const Permission = {
  EDIT_FIRM_PROFILE: 'EDIT_FIRM_PROFILE',
  EDIT_ALL_PROFILES: 'EDIT_ALL_PROFILES',
  APPROVE_FIRM_USERS: 'APPROVE_FIRM_USERS',
  APPROVE_ALL_USERS: 'APPROVE_ALL_USERS',
};

export const ADMIN_PERMISSIONS = [Permission.EDIT_ALL_PROFILES, Permission.APPROVE_ALL_USERS];

export const FIRM_ADMIN_PERMISSIONS = [Permission.EDIT_FIRM_PROFILE, Permission.APPROVE_FIRM_USERS];
