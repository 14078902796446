import { get } from '../common/api';

export const getDesks = async () => {
  try {
    const res = await get('/foreign_desk_representatives');
    return res;
  } catch (error) {
    return error;
  }
};
