import { get } from 'common/api';

export const getArticle = async id => {
  try {
    const { items } = await get(`/articles/${id}`);
    return items;
  } catch (error) {
    return error;
  }
};

export const getArticlesByTag = async (
  tags = '',
  keyword = '',
  offset = 0,
  sort = '',
  op = false
) => {
  try {
    const res = await get(
      `/articles?tags=${
        Array.isArray(tags) ? tags.map(obj => obj.sys.id).join(',') : tags
      }&q=${keyword}&offset=${offset}&sort=${sort}${op ? '&op=true' : ''}`
    );
    return res;
  } catch (error) {
    return error;
  }
};
