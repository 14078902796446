import { get } from '../common/api';

export const getEvents = async (year = '', isVirtual = '') => {
  try {
    const { data } = await get(`/events?year=${year}&virtual=${isVirtual}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getPastEvents = async (year = '', isVirtual = '') => {
  try {
    const { data } = await get(`/events/completed?year=${year}&virtual=${isVirtual}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getEvent = async id => {
  try {
    const items = await get(`/events/${id}`);
    return items;
  } catch (error) {
    return error;
  }
};
