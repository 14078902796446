import { get } from 'common/api';

export const getCommentsCount = async pageId => {
  try {
    const response = await get(`/comments?page_id=${pageId}`);
    if (response.original?.length) {
      return response.original[0].comments_count;
    }
    if (response.length) {
      return response[0].comments_count;
    }
    return 0;
  } catch (error) {
    return error;
  }
};
