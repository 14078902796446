import Collapsible from 'react-collapsible';

const MenuButton = ({ open, filterType }) => (
  <div className="collapsible__container">
    <p className="collapsible__text">
      {filterType === 'firm' ? 'Find a Member Firm' : 'Find a Person'}
    </p>
    <i className={`${open ? 'fas fa-angle-up' : 'fas fa-angle-down'} collapsible__icon`} />
  </div>
);

const CollapsibleMenu = ({ children, filterType }) => (
  <Collapsible
    open={true}
    trigger={<MenuButton filterType={filterType} />}
    triggerWhenOpen={<MenuButton open filterType={filterType} />}
    overflowWhenOpen={'visible'}
  >
    {children}
  </Collapsible>
);

export default CollapsibleMenu;
