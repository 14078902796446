import axios from 'axios';
import { filterTrueValues } from 'common/utils/objects';
import { uploadFiles } from 'services/upload';

export const registration = async params => {
  delete params.passwordAgain;

  const { cv_url, profile_photo_url } = params.userData;
  const { firmRole, networkRole, industry, specialty, certification } = params.professionalData;

  params.professionalData.firmRole = filterTrueValues(firmRole);
  params.professionalData.networkRole = filterTrueValues(networkRole);
  params.professionalData.industry = filterTrueValues(industry);
  params.professionalData.specialty = filterTrueValues(specialty);
  params.professionalData.certification = filterTrueValues(certification);

  let files = [];

  if (cv_url) files.push(cv_url[0]);
  if (profile_photo_url) files.push(profile_photo_url[0]);

  if (files.length) {
    const results = await uploadFiles({ files });
    if (results.length) {
      results.forEach(file => {
        const imgRegexPattern = /image/;
        const fileUrl = file.s3Path || file.path;
        if (imgRegexPattern.test(file.mimetype)) {
          params.userData.profile_photo_url = fileUrl;
        } else {
          params.userData.cv_url = fileUrl;
        }
      });
    }
  }

  try {
    await axios.post(`${process.env.CROWE_BASE_SERVER_URL}/register`, params);
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};
