import { useParams } from 'react-router-dom';
import { useGetById } from 'hooks/useGetById';
import { getResourceById } from 'services/resources';
import Spinner from 'common/components/Spinner/Spinner';

import ResourceDetail from 'common/components/Layout/ResourceDetail';

const ResourcePage = () => {
  const { slug } = useParams();

  const { data } = useGetById(getResourceById, slug) || [];

  return (
    <div className="resources__container">
      {!!data?.length && <ResourceDetail resource={data[0]} />}
    </div>
  );
};

export default ResourcePage;
