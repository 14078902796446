export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const multiCapitalize = string => {
  let arrayOfWords = string.split(' ');

  let capitalizedArray = arrayOfWords.map(word => capitalize(word.toLowerCase()));
  return capitalizedArray.join(' ');
};
