import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from 'react-toolbox/lib/dialog';
import { Verif } from './loadverif';
import { formatDate } from 'common/helpers/date';
import moment from 'moment';

const Checklists = ({ userid, verified_at, verify_name }) => {
  const [messageResponse, setMessageResponse] = useState('');
  const [activePop, setActivePop] = useState(false);

  const { t } = useTranslation('admin');

  const firmChecklist = () => {
    const messResp = <Verif user={userid} />;
    setMessageResponse(messResp);
    setActivePop(true);
  };

  const items = [
    t('overview'),
    t('approvedEmail'),
    t('firmCapabilities'),
    t('firmIndustries'),
    t('keyContacts'),
  ];

  const roles = [
    t('managingPartner'),
    t('internationalLiaisonPartner'),
    t('independenceContact'),
    t('brandOfficer'),
    t('digitalTransformationLeader'),
  ];
  return (
    <div className="checklist__container">
      <div className="admin__tab-container">
        <h2 className="checklist__subtitle">{t('firmChecklist')}</h2>
        <ul className="checklist__list">
          {items.map(item => (
            <div key={item} className="checklist__item">
              <i className="fas fa-check" />
              <li>{item}</li>
            </div>
          ))}

          <ol className="checklist__sublist">
            {roles.map(role => (
              <div key={role} className="checklist__item">
                <i className="fas fa-dot-circle" />
                <li>{role}</li>
              </div>
            ))}
          </ol>
        </ul>
        <div>
          <button className="checklist__btn" onClick={() => firmChecklist({})}>
            {t('weVerify')}
          </button>
        </div>
        <Dialog
          className="windowmess"
          actions={[
            {
              label: 'OK',
              onClick: () => {
                setActivePop(true);
              },
            },
          ]}
          active={activePop}
          title=""
          type="small"
        >
          <b>{messageResponse}</b>
        </Dialog>
        {verify_name.map(s => {
          if (s.contact_email) s.email = s.contact_email;
          if (s.contact_first_name) s.first_name = s.contact_first_name;
          if (s.contact_last_name) s.last_name = s.contact_last_name;
          if (s.contact_mobile_phone) s.mobile_phone = s.contact_mobile_phone;
          if (s.contact_office_direct_phone) s.office_direct_phone = s.contact_office_direct_phone;
          if (s.user_id) s.id = s.user_id;
          if (s.contact_photo_url) s.profile_photo_url = s.contact_photo_url;
          return (
            <p key={s.id}>
              Last verified: {formatDate(moment(verified_at))}, by {s.contact_first_name}
              {s.contact_last_name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Checklists;
