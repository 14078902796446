import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getArticle, getArticlesByTag } from 'services/articles';
import { formatDateWeekDay } from 'common/helpers/date';
import ResourcesList from 'common/components/Layout/ResourcesList';
import MarkDown from 'markdown-to-jsx';
import Article from 'common/components/Article/Article';
import Spinner from 'common/components/Spinner/Spinner';
import Slider from 'common/components/Slider/Slider';
import { HYVOR_WEBSITE_ID } from 'common/constants/hyvor';
import { useGetById } from 'hooks/useGetById';
import { Comments } from '@hyvor/hyvor-talk-react';
import { constructHyvorSso } from 'common/helpers/hyvor';

const ArticlePage = () => {
  const [relatedArticles, setRelatedArticles] = useState([]);

  const { slug } = useParams();
  const { data, isLoading } = useGetById(getArticle, slug) || [];
  const { resources } = data?.[0]?.fields || [];
  const { tags } = data?.[0]?.metadata || '';
  const { id } = data?.[0]?.sys || '';

  // This method set as relatedArticles the first 3 articles tagged with same tags the main article has, sorted by publication date, and filters the main article not to be shown twice. If length of relatedArticles is < 2 it fills the array with articles that have any tag the main article has.

  const handleRelatedArticles = async () => {
    if (tags) {
      const articles = await getArticlesByTag(tags);

      const allTagsArticles = articles.items
        .sort((a, b) => new Date(b.fields.publicationDate) - new Date(a.fields.publicationDate))
        .filter(article => article.sys.id !== id)
        .slice(0, 2);

      if (allTagsArticles.length < 2) {
        const anyTagArticles = await getArticlesByTag(tags, '', 0, '', true);

        const sliceCount = allTagsArticles.length ? 1 : 2;

        return setRelatedArticles([
          ...allTagsArticles,
          ...anyTagArticles.items
            .sort((a, b) => new Date(b.fields.publicationDate) - new Date(a.fields.publicationDate))
            .filter(article => {
              if (allTagsArticles.length) {
                return article.sys.id !== id && article.sys.id !== allTagsArticles?.[0].sys.id;
              }
              return article.sys.id !== id;
            })
            .slice(0, sliceCount),
        ]);
      }

      return setRelatedArticles(
        articles.items
          .sort((a, b) => new Date(b.fields.publicationDate) - new Date(a.fields.publicationDate))
          .filter(article => article.sys.id !== id)
          .slice(0, 2)
      );
    }
  };

  useEffect(() => {
    handleRelatedArticles();
  }, [tags]);

  useEffect(() => {
    if (data?.length) {
      document.title = `Crowe Global - Article - ${data[0].fields.title}`;
    }
  }, [data]);

  return (
    <div className="article__container">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="article__inner">
          {!!data?.length && (
            <div className="widget-todolist rounded overflow-hidden article__main">
              <div className="article__date">
                <p>{formatDateWeekDay(data[0].fields.publicationDate)}</p>
              </div>
              <h1 className="article__title">{data[0].fields.title}</h1>
              {!!data[0].fields.photos?.length ? (
                <Slider items={[data[0].fields.headerImage, ...data[0].fields.photos]} />
              ) : (
                <img
                  className="article__img"
                  src={data[0].fields.headerImage.fields.file.url}
                  alt={data[0].fields.headerImage.fields.file.title}
                />
              )}
              <div className="markdown">
                <MarkDown>{data[0].fields.body}</MarkDown>
              </div>
              <a
                href={`/directory/members/${data[0].fields.author.fields.portalUserId}`}
                className="article__author pb-10px border-bottom"
              >{`By ${data[0].fields.author.fields.fullName}`}</a>
              {/* Load Comments now */}
              <Comments
                website-id={HYVOR_WEBSITE_ID}
                page-id={data[0].sys.id}
                {...constructHyvorSso()}
              />
            </div>
          )}
          <div className="list__container">
            {resources && resources.length ? <ResourcesList resources={resources} /> : null}
            {relatedArticles.length
              ? relatedArticles.map(item => (
                  <div key={item.sys.id} className="list__card">
                    <Article item={item} />
                  </div>
                ))
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
