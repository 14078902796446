import { useState, useEffect } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import ArticleSkeleton from 'common/components/Skeletons/ArticleSkeleton';
import Article from 'common/components/Article/Article';
import Event from 'common/components/Events/Event';
import { getArticlesByTag } from 'services/articles';
import { getEvents } from 'services/events';

const Row = ({ tag, noTilesText }) => {
  const [tiles, setTiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { width } = useWindowSize();
  const isBigScreen = width > 1389;
  const tileAmount = isBigScreen ? 4 : 3;
  const skeletonClass = `home__widget home__widget--${isBigScreen ? '25' : '33'}`;

  const handleTiles = async () => {
    setIsLoading(true);
    const data = await getArticlesByTag(tag);
    setTiles(data.items);
    setIsLoading(false);
  };

  const handleEvents = async () => {
    setIsLoading(true);
    const data = await getEvents();
    setTiles(data);
    setIsLoading(false);
  };

  useEffect(() => {
    tag === 'events' ? handleEvents() : handleTiles();
  }, []);

  return (
    <div className="row__container">
      {isLoading ? (
        <div className="row__skeletons">
          <div className={skeletonClass}>
            <ArticleSkeleton />
          </div>
          <div className={skeletonClass}>
            <ArticleSkeleton />
          </div>
          <div className={skeletonClass}>
            <ArticleSkeleton />
          </div>
          {isBigScreen && (
            <div className={skeletonClass}>
              <ArticleSkeleton />
            </div>
          )}
        </div>
      ) : (
        <>
          {tiles.length ? (
            tiles.slice(0, tileAmount).map(tile => (
              <div
                key={tile.sys?.id || tile.id}
                className={`home__widget home__widget--${isBigScreen ? '25' : '33'}`}
              >
                {tag === 'events' ? <Event event={tile} /> : <Article item={tile} />}
              </div>
            ))
          ) : (
            <p className="m-0">{noTilesText}</p>
          )}
        </>
      )}
    </div>
  );
};

export default Row;
