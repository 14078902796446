/* Takes an array of ids and returns an array of strings.
If main roles and common roles exist will return ["main role/s", + "number other role/s"]
If only main roles exist will return ["main role/s",]
If only common roles exist will return ["first common role", + "nunber other role/s"] */

export const shortIdToTitleHandler = (roles = [], storedRoles) => {
  // [{id, name, visible, sort_order, key_contact}]
  const mainRoles = storedRoles?.filter(role => role.priority);

  // {id: nameOfRole}
  const rolesHelper =
    storedRoles.reduce((acc, role) => ({ ...acc, [role.id]: role.name }), {}) || {};

  // {id: nameOfMainRole}
  const mainRolesHelper =
    mainRoles.reduce((acc, role) => ({ ...acc, [role.id]: role.name }), {}) || {};

  // [id]
  const mainRolesIds = Object.keys(mainRolesHelper);

  // [id]
  const existingMainRoles = roles
    .filter(role => mainRolesIds.includes(role.toString()))
    .sort((a, b) => {
      const roleA = storedRoles.find(role => role.id === a);
      const roleB = storedRoles.find(role => role.id === b);
      return roleA.sort_order - roleB.sort_order;
    });

  // no network roles
  if (!roles.length) {
    return [];
  }

  // just 1 network role
  if (roles.length === 1) {
    return [rolesHelper[roles[0]]];
  }

  const commonRolesLength = roles.length - existingMainRoles.length;

  const final = commonRolesLength > 1 ? 'roles' : 'role';

  // not main roles, only common roles
  if (!existingMainRoles.length && commonRolesLength) {
    return [rolesHelper[roles[0]], `+ ${commonRolesLength - 1} other ${final}`];
  }

  // main roles and common roles
  if (existingMainRoles.length && commonRolesLength) {
    return [
      ...existingMainRoles.map(id => rolesHelper[id]),
      `+ ${commonRolesLength} other ${final}`,
    ];
  } else {
    return [...existingMainRoles.map(id => rolesHelper[id])];
  }
};

// Takes an array of ids and returns an array of titles
export const longIdToTitleHandler = (ids = [], storedValues) => {
  const valuesHelper =
    storedValues.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}) || {};

  return [...ids.map(item => valuesHelper[item])];
};

// Takes an array of objects and returns an array of titles
export const defaultIdToTitleHandler = storedValues => {
  return storedValues.reduce((acc, item) => [...acc, item.name], []) || [];
};

export const specialtiesIdToTitleHandle = (ids = [], storedCategories, storedSpecialties) => {
  const specialtiesHelper =
    storedSpecialties.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}) || {};

  const categoriesHelper =
    storedCategories.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}) || {};

  return [
    ...ids.map(item => ({
      [categoriesHelper[item.category]]: [
        ...item.specialties.map(specialtyId => specialtiesHelper[specialtyId]),
      ],
    })),
  ];
};

export const defaultSpecialtiesIdsToTitle = (storedCategories, storedSpecialties) => {
  const specialtiesHelper =
    storedSpecialties.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}) || {};

  return [
    ...storedCategories.map(category => ({
      category: category.name,
      id: category.id,
      specialties: category.specialties.map(id => specialtiesHelper[id]),
    })),
  ];
};

export const specialtiesIdsAndTitle = storedSpecialties => {
  return storedSpecialties.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}) || {};
};

export const allCategoriesSpecialties = capabilities => {
  let specialtiesIds = [];
  capabilities?.map(category => (specialtiesIds = [...specialtiesIds, ...category.specialties]));
  return specialtiesIds;
};

export const sortUsersByRole = (users, storedRoles) => {
  const sortOrderHelper =
    storedRoles.reduce((acc, role) => ({ ...acc, [role.id]: role.sort_order }), {}) || {};

  const usersHelper = users.map(user => ({
    ...user,
    network_roles: user.network_roles.sort((a, b) => a - b),
  }));

  const sortedUsers = usersHelper.sort(
    (a, b) => sortOrderHelper[a.network_roles[0]] - sortOrderHelper[b.network_roles[0]]
  );

  return sortedUsers;
};

export const roleIdsToNames = (roles, storedRoles) => {
  const rolesHelper =
    storedRoles.reduce((acc, role) => ({ ...acc, [role.id]: role.name }), {}) || {};

  return roles.map(role => ({ value: role, label: rolesHelper[role] }));
};

export const roleIdToName = (role, storedRoles) => {
  const rolesHelper =
    storedRoles.reduce((acc, role) => ({ ...acc, [role.id]: role.name }), {}) || {};

  return rolesHelper[role];
};

// It takes an array of objects like {specialties: [], category: 1} and adds a sort_order depending on the specialty_categories sort ordered stored in context, then returns capabilities sorted by that sort_order.
export const sortCapabilities = (capabilities, storedCategories) => {
  if (capabilities.length && !!capabilities[0].category) {
    return capabilities
      .map(specialty => ({
        ...specialty,
        sort_order: storedCategories.filter(category => {
          return category.id == specialty.category;
        })[0].sort_order,
      }))
      .sort((a, b) => a.sort_order - b.sort_order);
  }
  return [];
};
