import { useEffect } from 'react';
import Select from 'react-select';
import { useGet } from 'hooks/useGet';
import { getTags } from 'services/tags';
import { useTranslation } from 'react-i18next';

const LayoutFilters = ({
  values,
  setValues,
  items,
  setFilteredItems,
  searchPlaceholder,
  pageSize,
  setPageCount,
  sectionTag,
  page,
  setPage,
  getService,
  withTypes,
}) => {
  const { t } = useTranslation('filters');

  const { topics, type, search, sort } = values;

  const handleChange = (name, e) => {
    if (!e) {
      return setValues({ ...values, [name]: null });
    }
    if (name === 'topics') {
      setValues({
        ...values,
        topics: e.map(topic => ({ label: topic.label, value: topic.value })),
      });
    } else {
      setValues({ ...values, [name]: { label: e.label, value: e.value } });
    }
    setPage(0);
  };

  const handleFilters = async pageCount => {
    if (sectionTag !== 'noTagYet') {
      try {
        // getResourcesByTag parameters = array of tags, type, keyword and pageSize
        const data = await getService(
          [sectionTag, ...topics?.map(topic => topic.value)].filter(tag => tag).join(),
          type?.value,
          search?.value,
          pageCount,
          sort?.value
        );
        setPageCount(Math.ceil(data.total / pageSize));
        setFilteredItems(data.items);
      } catch (error) {}
    }
  };

  const { data: tags } = useGet(getTags);

  const sortedTopics = tags
    ?.map(tag => ({ label: tag.name, value: tag.sys.id }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))
    .filter(tag => tag.value !== sectionTag);

  const types = [
    { label: 'Document', value: 'document' },
    { label: 'Link', value: 'link' },
    { label: 'Video', value: 'video' },
  ];

  const sorting = [
    { label: 'Featured', value: '' },
    { label: 'A - Z', value: 'alpha' },
    { label: 'Z - A', value: '-alpha' },
    { label: 'Newest', value: '-pub' },
    { label: 'Oldest', value: 'pub' },
  ];

  useEffect(() => {
    handleFilters(page * pageSize);
  }, [page, sectionTag]);

  useEffect(() => {
    handleFilters();
  }, [values, sectionTag]);

  return (
    <div className="filter__container">
      <div className="filter__field">
        <Select
          options={sortedTopics}
          onChange={e => handleChange('topics', e)}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder={t('topic')}
          isClearable
          isMulti
          value={topics}
        />
        <i className="fa-solid fa-tag filter__icon" />
      </div>

      {withTypes && (
        <div className="filter__field">
          <Select
            options={types}
            onChange={e => handleChange('type', e)}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder={t('type')}
            isClearable
            value={type}
          />
          <i className="fa-solid fa-file filter__icon" />
        </div>
      )}

      <div className="filter__field">
        <Select
          options={sorting}
          onChange={e => handleChange('sort', e)}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder={t('sort')}
          value={sorting.find(option => option.value === sort)}
        />
        <i className="fa-solid fa-arrow-down-wide-short filter__icon" />
      </div>

      <div className="filter__field filter__field--search">
        <input
          onChange={e => handleChange('search', e.target)}
          value={search?.value || ''}
          placeholder={searchPlaceholder}
          className="form-control filter__search"
        />
        <i className="icon-search filter__icon" />
      </div>
    </div>
  );
};

export default LayoutFilters;
