export const articleSorter = news => {
  const priorityNews = news
    .filter(article => article.fields.priority)
    .sort((article1, article2) => article1.fields.priority - article2.fields.priority);
  const commonNews = news.filter(article => !article.fields.priority);

  return [...priorityNews, ...commonNews];
};

// Takes an array of objects and returns a new array ordered by object value by alpha 
export const sortObjArrByAlpha = (array, property) => {
  return array.sort((a, b) => {
    let textA = a[property];
    let textB = b[property];
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};
