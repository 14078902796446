import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'context/Store';
import { Link } from 'react-router-dom';
import UserDropdown from 'common/components/FirmDetail/UserDropdown';
import _ from 'lodash';
import { shortIdToTitleHandler } from 'common/utils/role';
import { multiCapitalize } from 'common/utils/strings';

const FirmStaff = ({
  staff,
  handleSetRole,
  handleSendPasswordResetEmail,
  handleDeactivate,
  handleApprove,
}) => {
  const [state] = useStore();
  const {
    network_roles: storedNetworkRoles,
    firm_roles: storedFirmRoles,
    hasAdminPermission,
  } = state;

  const { t } = useTranslation('directory');

  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleRole = role => {
    let adminString = '';
    if (role === 'ADMIN') adminString = 'Global Admin';
    if (role === 'FIRM_ADMIN') adminString = 'Firm Admin';
    return adminString;
  };

  const approvedStatus = 'APPROVED';
  const rejectedStatus = 'REJECTED';
  return (
    <div className="staff__container mt-20px">
      {staff.map(user => {
        return (
          <div
            className={`staff__card ${
              user.status !== approvedStatus ? 'staff__card--deactivated' : ''
            }`}
            key={user.id}
          >
            {user.status === rejectedStatus && (
              <div className="staff__corner">
                <span />
              </div>
            )}
            <div className="staff__column">
              {user.profile_photo_url ? (
                <Link className="contact-card__img" to={`/directory/members/${staff.user_id}`}>
                  <img src={user.profile_photo_url} alt={`${user.first_name} ${user.last_name}`} />
                </Link>
              ) : (
                <i className="fas fa-user-circle card__icon--user text-secondary" />
              )}
            </div>
            <Link
              to={`/directory/members/${user.id}`}
              className="card__column card__column--second"
            >
              <p className="staff__text--main">
                {multiCapitalize(`${user.first_name} ${user.last_name}`)}
              </p>
              {!!user.network_roles.length &&
                shortIdToTitleHandler(user.network_roles, storedNetworkRoles).map((role, index) => (
                  <li key={index} className="card__item">
                    {role}
                  </li>
                ))}
              {!!user.firm_roles.length &&
                shortIdToTitleHandler([user.firm_roles[0]], storedFirmRoles).map((role, index) => (
                  <li className="card__item ellipsis-sl" key={index}>
                    {t('firmRole')}: {role}
                  </li>
                ))}
              {hasAdminPermission && <li className="card__item ellipsis-sl">{user.firm}</li>}
              <li className="card__item ellipsis-sl">{user.city}</li>
              <li className="card__item ellipsis-sl">{handleRole(user.role)}</li>
            </Link>
            <UserDropdown
              id={user.id}
              email={user.email}
              status={user.status}
              onSetRolePrivilege={handleSetRole}
              onSendPasswordResetEmail={handleSendPasswordResetEmail}
              onDeactivate={handleDeactivate}
              onApprove={handleApprove}
              staffRole={user.role}
              setSelectedUserId={setSelectedUserId}
              selectedUserId={selectedUserId}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FirmStaff;
