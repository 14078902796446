import { useEffect } from 'react';
import { useStore } from 'context/Store';
import Help from 'common/components/Registration/Help';
import { useTranslation } from 'react-i18next';
import { specialtiesIdsAndTitle } from 'common/utils/role';

const ServiceProviderInfo = ({ register, watch, setCheckboxError, trigger }) => {
  const [state] = useStore();
  const {
    industries: storedIndustries,
    specialties: storedSpecialties,
    specialty_categories,
    certifications: storedCertifications,
  } = state;

  const { t } = useTranslation('userProfile');

  const specialtiesIdsAndNames = specialtiesIdsAndTitle(storedSpecialties);

  const checkboxCount = Object.values(watch('professionalData.specialty')).filter(
    entry => entry
  ).length;

  useEffect(() => {
    setCheckboxError(checkboxCount > 3);
  }, [checkboxCount]);

  useEffect(() => {
    trigger();
  }, []);

  return (
    <div className="user__data">
      <h2 className="user__title">{t('serviceProviderDetails')}</h2>

      <h3 className="user__subtitle">{t('serviceProviderSubtext')}</h3>
      <p className="user__comment">{t('serviceProviderInstruction')}</p>

      <div className="user__help mb-0">
        <Help text={t('serviceProviderTip')} icon="fas fa-exclamation-circle" />
      </div>

      <h3 className="user__section-subtitle">{t('chooseSpecialty')}</h3>
      <div className="user__checklist">
        {specialty_categories.length
          ? specialty_categories.map(category => {
              return (
                <div className="user__items" key={category.id}>
                  <h4 className="user__category">{category.name}</h4>
                  {category.specialties.map(specialty => (
                    <div className="user__item" key={specialty}>
                      <input
                        {...register(`professionalData.specialty.${specialty}`)}
                        name={`professionalData.specialty.${specialty}`}
                        type="checkbox"
                        className="user__item"
                      />
                      <label>{specialtiesIdsAndNames[specialty]}</label>
                    </div>
                  ))}
                </div>
              );
            })
          : null}
      </div>
      {checkboxCount > 3 && <p className="profile__error">{t('checkboxError')}</p>}

      <h3 className="user__section-subtitle">{t('industryExperience')}</h3>
      <div className="user__checklist">
        {storedIndustries.length
          ? storedIndustries.map(industry => {
              return (
                <div className="user__item" key={industry.id}>
                  <input
                    {...register(`professionalData.industry.${industry.id}`)}
                    type="checkbox"
                    name={`professionalData.industry.${industry.id}`}
                    className="user__item"
                  />
                  <label>{industry.name}</label>
                </div>
              );
            })
          : null}
      </div>

      <h3 className="user__section-subtitle">{t('certifications')}</h3>
      <div className="user__checklist--condensed mb-30px">
        {storedCertifications.length
          ? storedCertifications.map(option => {
              return (
                <div className="user__item" key={option.id}>
                  <input
                    {...register(`professionalData.certification.${option.id}`)}
                    name={`professionalData.certification.${option.id}`}
                    type="checkbox"
                    className="user__item"
                  />
                  <label>{option.name}</label>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ServiceProviderInfo;
