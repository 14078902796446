import { get } from '../common/api';

export const getSections = async () => {
  try {
    const { items } = await get('/sections');
    return items;
  } catch (error) {
    return error;
  }
};

export const getSectionById = async slug => {
  try {
    // to use id add a dot before slug '/sections/.id
    const res = await get(`/sections/${slug}`);
    return res;
  } catch (error) {
    return error;
  }
};
