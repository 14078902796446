import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getResourcesByTag } from 'services/resources';
import { useTranslation } from 'react-i18next';
import ResourceWidgetSkeleton from 'common/components/Skeletons/ResourceWidgetSkeleton';
import ListResource from 'common/components/Layout/ListResource';

const ResourcesWidget = ({ title, link, tags = [] }) => {
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('home');

  const handleContent = async () => {
    setIsLoading(true);
    const data = await getResourcesByTag(tags);
    setContent(data.items.slice(0, 5));
    setIsLoading(false);
  };

  useEffect(() => {
    handleContent();
  }, []);

  return (
    <div className="widget-todolist rounded h-100">
      <div className="widget-todolist-header widget__header">
        <i className="fas fa-download text-warning fs-16px" />
        <b className="widget__heading ellipsis-sl">{title}</b>
      </div>
      {isLoading ? (
        <ResourceWidgetSkeleton />
      ) : (
        <div className="m-0">
          {!!content.length ? (
            <>
              {content.map((item, key) => (
                <div key={item.sys.id} className="widget__item-container">
                  <ListResource resource={item} index={key} />
                </div>
              ))}
              <div className="widget__footer">
                <Link
                  to={link}
                  className="text-decoration-none btn btn-primary btn-sm fs-16px text-white widget__btn"
                >
                  {t('widgets.viewAll')}
                </Link>
              </div>
            </>
          ) : (
            <p className="widget__item widget__item--nb widget__description">
              {t('widgets.noResources')}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ResourcesWidget;
