export const groupByDesk = users => {
  let desks = [];
  let languages = users.flatMap(user => user.languages);
  let languageSet = new Set(languages);
  languageSet.forEach(language => {
    let desk = {};
    let deskUsers = users.map(user => {
      if (user.languages.includes(language)) {
        return user;
      }
    });
    deskUsers = deskUsers.filter(user => user !== undefined);
    desk[language.toLowerCase()] = deskUsers;
    desks.push(desk);
  });
  return desks;
};
