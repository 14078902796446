import { useState } from 'react';
import { resourceIconHandler } from 'common/helpers/file';
import Message from 'common/components/Layout/Message';
import Prompt from 'common/components/Prompt/Prompt';
import { isVideo } from 'common/helpers/file';
import { Link } from 'react-router-dom';

const ListResource = ({ resource, index }) => {
  const [prompt, setPrompt] = useState(false);

  const url = resource.fields.url || resource.fields.media.fields.file.url;

  const resourceType = resource.fields.resourceType;

  const togglePrompt = () => {
    setPrompt(!prompt);
  };

  const {
    fields: { external },
  } = resource;

  const handleLink = () => {
    const hasVideoFormat = isVideo(resourceType);

    return external ? (
      <>
        <div className="cursor-pointer" onClick={togglePrompt}>
          {resourceIconHandler(resourceType)}
        </div>
        <button className="widget__description mx-10px" onClick={togglePrompt}>
          {resource.fields.title}
        </button>
        {prompt && (
          <Prompt>
            <Message url={url} handleClose={togglePrompt} />
          </Prompt>
        )}
      </>
    ) : hasVideoFormat ? (
      <>
        <Link to={`/resource/.${resource.sys.id}`}>{resourceIconHandler(resourceType)}</Link>
        <div className="d-flex-col mx-10px">
          <Link
            to={`/resource/.${resource.sys.id}`}
            className="text-decoration-none text-black lh-sm"
          >
            <p className="widget__description ellipsis-sl">{resource.fields.title}</p>
          </Link>
        </div>
      </>
    ) : (
      <>
        <a target="_blank" href={url}>
          {resourceIconHandler(resourceType)}
        </a>
        <div className="d-flex-col mx-10px">
          <a className="text-decoration-none text-black lh-sm" target="_blank" href={url}>
            <p className="widget__description ellipsis-sl">{resource.fields.title}</p>
          </a>
        </div>
      </>
    );
  };

  return <div className={`list__item ${index ? 'border-top' : ''}`}>{handleLink()}</div>;
};

export default ListResource;
