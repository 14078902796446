import { getToken, removeToken } from '../utils/localStorage';

const getApiUrl = () => {
  return process.env.CROWE_API_URL;
};

const getBaseURL = url => {
  return process.env.CROWE_BASE_SERVER_URL + (url.startsWith('/') ? '' : '/') + url;
};

const getURL = url => {
  return getApiUrl() + (url.startsWith('/') ? '' : '/') + url;
};

const getHeaders = (opts = { dataType: 'json' }) => {
  const accessToken = getToken();
  let headers = {
    Accept: 'application/json',
  };

  if (opts.dataType === 'json') {
    headers['Content-Type'] = 'application/json';
  }

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return headers;
};

const checkStatus = response => {
  if (response.ok) {
    return response;
  } else {
    return processError(response);
  }
};

const processError = async error => {
  const errors = await parseJSON(error);
  return Promise.reject({
    status: error.status,
    errors: errors || undefined,
  });
};

const parseJSON = async response => {
  try {
    return await response.json();
  } catch (err) {
    return undefined;
  }
};

const prepData = (dataType, data) => {
  if (dataType === 'json') {
    return data ? JSON.stringify(data) : undefined;
  }

  if (dataType === 'fileUpload') {
    let formData = new FormData();

    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        // Multiple files
        data[key].forEach(file => {
          if (typeof file === 'object') {
            formData.append(key, file, file.name);
          }
        });
      } else {
        // Single file
        formData.append(key, data[key]);
      }
    });

    return formData;
  }
};

const makeRequest = (url, method, body, opts = { dataType: 'json' }, nonApi) => {
  if (!opts.dataType) opts.dataType = 'json';
  const options = {
    credentials: 'same-origin',
    headers: getHeaders({ dataType: opts.dataType }),
    method,
    body: prepData(opts.dataType, body),
  };

  const fetchUrl = nonApi ? getBaseURL(url) : getURL(url);

  return fetch(fetchUrl, options)
    .then(checkStatus)
    .then(result => {
      if (opts.dataType === 'json' || opts.dataType === 'fileUpload') return parseJSON(result);
      return result;
    })
    .catch(err => {
      if (
        err.errors.message === 'jwt expired' ||
        err.errors.message === 'invalid token' ||
        err.errors.message === 'invalid signature'
      ) {
        removeToken();
        window.location = '/login';
      }
      if (
        (err.errors.message.includes('The email') &&
          err.errors.message.includes('already exists.')) ||
        err.errors.message.includes('No user found with email')
      ) {
        throw new Error(err.errors.message);
      }
      if (
        err.errors.message ===
          'Credentials provided are incorrect. If you are having trouble logging in, contact directory@crowe.org.' ||
        err.errors.message === 'Unauthorized. Your membership is still pending.'
      ) {
        throw new Error(err.errors.message);
      }
      if (err.errors.message) {
        throw new Error(err.errors.message);
      }
    });
};

const get = (url, opts, nonApi = false) => {
  return makeRequest(url, 'GET', null, opts, nonApi);
};

const post = (url, body, opts, nonApi = false) => {
  return makeRequest(url, 'POST', body, opts, nonApi);
};

const put = (url, body, opts) => {
  return makeRequest(url, 'PUT', body, opts);
};

const patch = (url, body, opts) => {
  return makeRequest(url, 'PATCH', body, opts);
};

const del = (url, body, opts) => {
  return makeRequest(url, 'DELETE', body, opts);
};

export { get, post, patch, del, put, getBaseURL, getToken };
