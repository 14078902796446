import excel from 'assets/icons/svg/excel.svg';
import image from 'assets/icons/svg/image.svg';
import pdf from 'assets/icons/svg/pdf.svg';
import ppt from 'assets/icons/svg/ppt.svg';
import video from 'assets/icons/svg/video.svg';
import word from 'assets/icons/svg/word.svg';
import link from 'assets/icons/svg/link.svg';
import zip from 'assets/icons/svg/zip.svg';
import txt from 'assets/icons/svg/txt.svg';

export const getFileExtension = fileName => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts[parts.length - 1].toUpperCase() : null;
};

export const formatFileSize = bytes => {
  if (bytes < 1000) return `${bytes} bytes`;
  if (bytes < 1000 * 1000) return `${(bytes / 1000).toFixed(2)} KB`;
  return `${(bytes / 1000000).toFixed(2)} MB`;
};

export const getExtensionImage = extension => {
  const info = {
    PDF: 'pdf.png',
    DOC: 'word.png',
    DOT: 'word.png',
    XLS: 'excel.png',
    PPT: 'powerpoint.png',
  };

  const type = extension.substring(0, 3);
  if (info[type]) {
    return `/images/file-icon/${info[type]}`;
  } else {
    return null;
  }
};

export const resourceIconHandler = resourceType => {
  switch (resourceType) {
    case 'PDF':
      return <img className="resource__svg" src={pdf} />;
      break;
    case 'PowerPoint':
      return <img className="resource__svg" src={ppt} />;
      break;
    case 'Excel':
      return <img className="resource__svg" src={excel} />;
      break;
    case 'Word':
      return <img className="resource__svg" src={word} />;
      break;
    case 'Image':
      return <img className="resource__svg" src={image} />;
      break;
    case 'Video':
      return <img className="resource__svg" src={video} />;
      break;
    case 'Link':
      return <img className="resource__svg" src={link} />;
      break;
    case 'Zip':
      return <img className="resource__svg" src={zip} />;
      break;
    case 'Text':
      return <img className="resource__svg" src={txt} />;
    default:
      break;
  }
};

export const fileButtonHandler = resourcetype => {
  const extensions = {
    // PDF
    PDF: 'Download',
    // PPT
    PowerPoint: 'Download',
    // EXCEL
    Excel: 'Download',
    // WORD
    Word: 'Download',
    // IMG
    Image: 'Download',
    // LINK
    Link: 'Link',
    Video: 'View',
    // ZIP
    Zip: 'Download',
    Text: 'Download',
  };

  return extensions[resourcetype];
};

export const isVideo = resourceType => resourceType === 'Video';
