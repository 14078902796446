import { useEffect } from 'react';
import { getPageById } from 'services/pages';
import MarkDown from 'markdown-to-jsx';
import Spinner from 'common/components/Spinner/Spinner';
import ResourcesList from 'common/components/Layout/ResourcesList';
import { useGetById } from 'hooks/useGetById';

const Page = ({ slug }) => {

  const { data: pageContent, isLoading } = useGetById(getPageById, slug) || [];

  useEffect(() => {
    if (pageContent?.length) {
      document.title = `Crowe Global - ${pageContent[0].fields.title}`;
    }
  }, [pageContent]);

  return (
    <div className="page__container">
      {isLoading ? (
        <Spinner />
      ) : (
        !!pageContent?.length && (
          <div className="page__body">
            <div className="page__content">
              <div className="markdown">
                <MarkDown>{pageContent[0].fields.body}</MarkDown>
              </div>
            </div>
            <div className="page__tiles">
              {pageContent[0].fields.resources && (
                <ResourcesList resources={pageContent[0].fields.resources} />
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Page;
