export const REGISTRATION_DEFAULT_VALUES = {
  userData: {
    accepted_current_tos: true,
    salutation: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    office_direct_phone_country_code: '',
    office_direct_phone: '',
    mobile_phone_country_code: '',
    mobile_phone: '',
    reasonForRegistering: '',
    bio: '',
    cv_url: '',
    profile_photo_url: '',
    lang: '',
    linkedin: '',
  },
  professionalData: {
    networkRole: {},
    firmRole: {},
    specialty: {},
    industry: {},
    certification: {},
    city: '',
    country: '',
    firm: '',
  },
};

export const reasonOptions = [
  { value: 'Access brand resources', label: 'Access brand resources' },
  { value: 'Access service line resources', label: 'Access service line resources' },
  { value: 'Find a firm or professional', label: 'Find a firm or professional' },
  { value: 'Register for an event', label: 'Event Registration' },
];

export const salutationOptions = [
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
];

export const foreignDeskRole = 'Foreign Desk Representative';
