import Event from 'common/components/Events/Event';
import NoResults from 'common/components/NoResults/NoResults';

const EventsList = ({ filteredEvents, t }) => {
  return (
    <>
      <div className="layout__row">
        {filteredEvents?.length ? (
          filteredEvents.map(event => <Event key={event.id} event={event} />)
        ) : (
          <NoResults icon="icon-search" title={t('noEvents')} comment={t('tryDifferentFilters')} />
        )}
      </div>
    </>
  );
};
export default EventsList;
