const NoResults = ({ icon, title, comment }) => (
  <div className="results__container">
    <div className="results__content">
      <div className="results__icon-container">
        {icon && <i className={`results__icon ${icon}`} />}
      </div>
      <div className="widget-todolist-item">
        <div className="widget-todolist-content">
          {title && <h2 className="results__title">{title}</h2>}
          {comment && <p className="results__help">{comment}</p>}
        </div>
      </div>
    </div>
  </div>
);

export default NoResults;
