import { useState, useEffect } from 'react';
import { getSectionById } from 'services/navigation';

export const useGetSection = (section, id) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSection = async () => {
    try {
      setIsLoading(true);
      setData(await getSectionById(section));
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    handleSection();
  }, [section, id]);

  return { data, isLoading, isSuccess };
};
