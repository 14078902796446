import times from 'lodash/times';

const PageSelector = ({ value, disabled, active, onClick, children }) => {
  const classNames = [
    'pager__selector',
    active ? 'pager__active' : '',
    disabled ? 'pager__disabled' : '',
  ].join(' ');

  return (
    <div onClick={() => !active && !disabled && onClick && onClick(value)} className={classNames}>
      {children}
    </div>
  );
};

const Pager = ({ total, offset, limit, adjacent, onSelectPage }) => {
  const active = Math.floor(offset / limit) + 1;
  const last = Math.ceil(total / limit);

  const previousPages = times(adjacent, n => active - (adjacent - n)).filter(n => n > 0);
  const nextPages = times(adjacent, n => active + n + 1).filter(n => n <= last);

  if (total < limit) {
    return null;
  }

  const Ellipsis = () => <span className="pager__ellipsis">…</span>;

  return (
    <div className="pager__container">
      <div className="pager__inner">
        {active > 1 && (
          <PageSelector value={active - 1} onClick={onSelectPage}>
            <i className="fa-solid fa-chevron-left pager__selector fs-12px" />
          </PageSelector>
        )}

        {active > adjacent + 1 && (
          <PageSelector value={1} onClick={onSelectPage}>
            1
          </PageSelector>
        )}

        {active > adjacent + 2 && <Ellipsis />}

        {previousPages.map(n => (
          <PageSelector key={`prevPage-${n}`} value={n} onClick={onSelectPage}>
            {n}
          </PageSelector>
        ))}

        <PageSelector value={active} active={true}>
          {active}
        </PageSelector>

        {nextPages.map(n => (
          <PageSelector key={`nextPage-${n}`} value={n} onClick={onSelectPage}>
            {n}
          </PageSelector>
        ))}

        {active < last - adjacent - 1 && <Ellipsis />}

        {active < last - adjacent && (
          <PageSelector value={last} onClick={onSelectPage}>
            {last}
          </PageSelector>
        )}

        {active < last && (
          <PageSelector value={active + 1} onClick={onSelectPage}>
            <i className="fa-solid fa-chevron-right pager__selector fs-12px" />
          </PageSelector>
        )}
      </div>
    </div>
  );
};

Pager.defaultProps = {
  adjacent: 2,
};

export default Pager;
