import { useMemo } from 'react';
import UserDropdown from 'common/components/FirmDetail/UserDropdown';
import { useStore } from 'context/Store';
import { shortIdToTitleHandler } from 'common/utils/role';
import { multiCapitalize } from 'common/utils/strings';
import _ from 'lodash';

const PendingUser = ({
  user,
  approve,
  reject,
  optionsClick,
  selectedUserId,
  setSelectedUserId,
  handleSendPasswordResetEmail,
  t,
}) => {
  const [state] = useStore();

  const { network_roles: storedNetworkRoles, firm_roles: storedFirmRoles } = state;

  const { firm_roles, network_roles } = user;
  let firmRole = _.head(firm_roles);

  const networkRoles = useMemo(() => {
    return shortIdToTitleHandler(network_roles, storedNetworkRoles);
  }, [network_roles, storedNetworkRoles]);

  const firmRoles = useMemo(() => {
    return shortIdToTitleHandler(firm_roles, storedFirmRoles);
  }, [firm_roles, storedFirmRoles]);

  if (firm_roles && firm_roles.length >= 2) {
    firmRole = t('andMoreRoles', { role: firmRole, count: firm_roles.length - 1 });
  }

  const handleApprove = async () => {
    try {
      await approve(user.id);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async () => {
    try {
      await reject(user.id);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="pending__card">
      <div className="pending__img">
        {user.profile_photo_url ? (
          <img src={user.profile_photo_url} alt={`${user.first_name} ${user.last_name}`} />
        ) : (
          <i className="fas fa-user-circle card__icon--user text-secondary" />
        )}
      </div>

      <div className="pending__info">
        <span className="pending__name">
          {multiCapitalize(`${user.first_name} ${user.last_name}`)}
        </span>
        <span className="pending__data">{!!networkRoles.length && networkRoles.join(' ')}</span>
        <span className="pending__data">{!!firmRoles.length ? firmRoles.join(' ') : ''}</span>
        <span className="pending__data">{user.office_city}</span>

        <div className="pending__actions">
          <button className="pending__btn--approve" onClick={handleApprove}>
            Approve
          </button>
          <button className="pending__btn--reject" onClick={handleReject}>
            Reject
          </button>
        </div>
        <div className="pending__options">
          <UserDropdown
            id={user.id}
            email={user.email}
            onSendPasswordResetEmail={handleSendPasswordResetEmail}
            setSelectedUserId={setSelectedUserId}
            selectedUserId={selectedUserId}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingUser;
