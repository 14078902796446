import _ from 'lodash';
import { MAX_SIZE } from 'common/constants/file';

export const required = (value, t) => (!!value ? undefined : t('required'));

export const requiredPicture = (value, t) => (value?.length ? undefined : t('required'));

export const fileSize = (value, t, input) => {
  return typeof value === 'object' && value && value[0]?.size > MAX_SIZE
    ? t('sizeLimit', { input })
    : undefined;
};

export const number = (value, t) => (value && isNaN(Number(value)) ? t('number') : undefined);

export const email = (value, t) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)
    ? t('invalidEmail')
    : undefined;

export const validCountryCode = (value, t) =>
  value && !/^\d{0,6}$/.test(value) ? t('invalidCountryCode') : undefined;

export const codeRequired = (code, phone, t) => {
  if (!code && phone) {
    return t('requiredCode');
  }
  if ((!!code && !!phone) || (!code && !phone)) {
    return undefined;
  }
};

export const phoneRequired = (code, phone, t) => {
  if (code && !phone) {
    return t('requiredPhone');
  }
  if ((!!code && !!phone) || (!code && !phone)) {
    return undefined;
  }
};

export const containsOnlyNumbers = (value, t) => {
  if (value) {
    return /^[\d\s]+$/.test(value) ? undefined : t('numbers');
  }
};

export const phoneNumber = (value, t) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? t('invalidPhone') : undefined;

export const passwordMatch = (value, valueToMatch, t) => {
  return value != valueToMatch ? t('passNotMatching') : undefined;
};

export const validPassword = (value, t) => {
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  if (!strongRegex.test(value)) {
    return t('invalidPassword');
  }
};

export const validPasswordAndRequirements = (value, t) => {
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  if (!strongRegex.test(value)) {
    return t('passwordRequirements');
  }
};

export const isValidUrl = (string, t) => {
  if (string) {
    return string.includes('http') && string.includes('.') && !string.includes(' ')
      ? undefined
      : t('invalidUrl');
  }
};

export const isValidLinkedIn = (string, t) => {
  if (string) {
    return string.includes('http') && string.includes('linkedin.com') ? undefined : t('invalidUrl');
  }
};
