import axios from 'axios';

export const getRegions = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/regions`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCities = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/cities`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCountries = async () => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/countries`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getCountryById = async id => {
  try {
    const res = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/countries/${id}`);

    return res.data;
  } catch (error) {
    return error;
  }
};
