import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'context/Store';
import {
  upcomingEventsPath,
  pastEventsPath,
  directoryFirmsPath,
  directoryMembersPath,
  directoryDesksPath,
} from 'common/helpers/path';

const GLOBAL_DIRECTORY_TITLE = 'Global Directory';
const MEMBER_FIRMS_TITLE = 'Member Firms';
const EVENTS_TITLE = 'Events';
const PEOPLE_TITLE = 'People';
const FOREIGN_DESKS_TITLE = 'Foreign Desks';
const UPCOMING_EVENTS_TITLE = 'Upcoming Events';
const PAST_EVENTS_TITLE = 'Past Events';

const Page = ({ page, section, pathname, pageType, setOpen }) => {
  const [pageSlug, setPageSlug] = useState('');
  const [_, dispatch] = useStore();

  const { slug, title } = page.fields;
  const { id } = page.sys;

  let pathnameArray = pathname.split('/');

  const handlePageSlug = () => {
    setPageSlug(pathnameArray[pathnameArray.length - 1]);
  };

  const handleActiveClass = () => {
    if (
      // Checks page slug vs pathname slug
      slug === pageSlug ||
      // Checks page id vs pathname slug
      pageSlug.includes(id) ||
      // Checks if there is subPage slug in pathname and compares it to the page slug
      (pathnameArray.length > 4 && slug === pathnameArray[pathnameArray.length - 2]) ||
      // Checks if there is subPage slug in pathname and compares it to the page id
      pathnameArray[pathnameArray.length - 2].includes(id) ||
      // Checks for custom pages title and compares it to the pathname
      // TODO use slugs instead of titles
      (title === MEMBER_FIRMS_TITLE && pathname.includes(directoryFirmsPath)) ||
      (title === PEOPLE_TITLE && pathname.includes(directoryMembersPath)) ||
      (title === FOREIGN_DESKS_TITLE && pathname.includes(directoryDesksPath)) ||
      (title === UPCOMING_EVENTS_TITLE && pathname.includes(upcomingEventsPath)) ||
      (title === PAST_EVENTS_TITLE && pathname.includes(pastEventsPath))
    ) {
      return 'sidebar__page--active';
    }
    return '';
  };

  const handlePageType = () => {
    dispatch({ type: 'SET_PAGE_TYPE', payload: pageType });
  };

  const handleLink = () => {
    if (section.fields.title === GLOBAL_DIRECTORY_TITLE) {
      switch (title) {
        case MEMBER_FIRMS_TITLE:
          return directoryFirmsPath;
        case PEOPLE_TITLE:
          return directoryMembersPath;
        case FOREIGN_DESKS_TITLE:
          return directoryDesksPath;
        default:
          break;
      }
    }
    if (section.fields.title === EVENTS_TITLE && title === UPCOMING_EVENTS_TITLE) {
      return upcomingEventsPath;
    }
    if (section.fields.title === EVENTS_TITLE && title === PAST_EVENTS_TITLE) {
      return pastEventsPath;
    } else {
      return `/${section.fields.slug ?? `.${section.sys.id}`}/pages/${slug ?? `.${id}`}`;
    }
  };

  useEffect(() => {
    handlePageSlug();
  }, [pathname]);

  useEffect(() => {
    if (slug === pageSlug || pageSlug.includes(id)) {
      handlePageType();
    }
  }, [slug, pageSlug]);

  return (
    <div className="menu-item" onClick={() => (setOpen ? setOpen(false) : null)}>
      <Link to={handleLink()} className={`menu-link sidebar__page ${handleActiveClass()}`}>
        <div className="menu-text">{title}</div>
      </Link>
    </div>
  );
};

export default Page;
