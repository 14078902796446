import { useTranslation } from 'react-i18next';

const Help = ({ text, link, icon, linkText }) => {
  const { t } = useTranslation('registration');

  return (
    <div className="help__container">
      <i
        className={`
      help__tooltip ${icon}`}
      />
      <span className="help__text">
        {text}
        {link && (
          <a className="help__link" href={link}>
            {linkText ? linkText : t('clickHere')}
          </a>
        )}
      </span>
    </div>
  );
};

export default Help;
