import { get } from '../common/api';

export const getCommitteesById = async slug => {
  try {
    const { items } = await get(`/committee_pages/${slug}`);
    return items;
  } catch (error) {
    return error;
  }
};
