const Prompt = ({ children }) => {
  return (
    <div className="modal__bg">
      <div className="modal__align">
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
};

export default Prompt;
