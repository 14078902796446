const MemberDetailSkeleton = () => {
  return (
    <div className="skeleton__member-detail is-loading mt-20px">
      <div className="skeleton__image mb-20px" />
      <div className="content">
        <h2></h2>
        <p></p>
      </div>
      <div className="skeleton__section">
        <h3></h3>
        <h3></h3>
        <h3></h3>
      </div>
      <div className="skeleton__section">
        <h3></h3>
        <h3></h3>
        <h3></h3>
      </div>
      <div className="skeleton__section">
        <h3></h3>
        <h3></h3>
        <h3></h3>
      </div>
    </div>
  );
};

export default MemberDetailSkeleton;
