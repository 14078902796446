import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCommentsCount } from 'services/hyvor';
import { useGetById } from 'hooks/useGetById';

const Article = ({ item }) => {
  const { t } = useTranslation('articles');
  const { id } = item.sys;

  const textLength = 120;

  const { data: comments } = useGetById(getCommentsCount, id);

  return (
    <Link to={`/articles/${item.fields.slug ?? `.${id}`}`} className="article__card">
      <div className="articles__container" data-id="widget">
        <div className="articles__header">
          <img
            className="articles__img"
            src={item.fields.headerImage.fields.file.url}
            alt={item.fields.headerImage.fields.title}
          />
          <h3 className="articles__title">{item.fields.title}</h3>
        </div>
        {item.fields.summary && (
          <div className="articles__body">
            <p className="articles__text">
              {item.fields.summary.length > textLength ? (
                <>
                  {item.fields.summary.slice(0, textLength)}
                  <span>... </span>
                  <span className="articles__more-link">{t('more')}</span>
                </>
              ) : (
                item.fields.summary
              )}
              {item.fields.priority && <i className="icon-thumbtack articles__thumbtack" />}

              {!!comments && (
                <span className="articles__comment">
                  <i className="icon-comment-solid" />
                </span>
              )}
            </p>
          </div>
        )}
      </div>
    </Link>
  );
};

export default Article;
