import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MemberCard from 'common/components/Directory/Cards/MemberCard';
import { useStore } from 'context/Store';
import { sortUsersByRole } from 'common/utils/role';
import { getFirms } from 'services/firm';
import FirmCard from 'common/components/Directory/Cards/FirmCard';

const Overview = ({ firm, contacts }) => {
  const [state] = useStore();
  const { network_roles } = state;

  const { t } = useTranslation('firmProfile');

  const [umbrellas, setUmbrellas] = useState([]);
  const sortedContacts = sortUsersByRole(contacts, network_roles);

  const handleUmbrella = async () => {
    try {
      if (firm.firm_type === 'Umbrella') {
        const data = await getFirms(0, { parent_firm_id: firm.id }, null, 100);
        setUmbrellas(data.firms);
      } else {
        setUmbrellas([]);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    handleUmbrella();
  }, [firm]);

  return (
    <div>
      {/* Summary */}
      <div className="directory__overview">
        <h4 className="fs-18px fw-700 my-20px">{t('about')}</h4>
        <p className="detail__description">{firm.description}</p>
      </div>

      {/* Key Contacts */}
      {!!sortedContacts.length && (
        <>
          <h4 className="fs-18px fw-700 my-20px">{t('keyContacts')}</h4>
          <div className="directory__key-contacts">
            {sortedContacts.map(contact => (
              <MemberCard
                key={contact.user_id}
                id={contact.user_id}
                first_name={contact.contact_first_name}
                last_name={contact.contact_last_name}
                profile_photo_url={contact.contact_photo_url}
                email={contact.contact_email}
                network_roles={contact.network_roles}
              />
            ))}
          </div>
        </>
      )}

      {/* Umbrellas */}
      {!!umbrellas.length && (
        <>
          <h4 className="fs-18px fw-700 my-20px">{t('umbrellaMembers')}</h4>
          <div className="detail__umbrellas">
            {umbrellas.map(umbrella => (
              <FirmCard
                id={umbrella.id}
                name={umbrella.name}
                country={umbrella.country}
                country_code={umbrella.country_code}
                firm_type={umbrella.firm_type}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
