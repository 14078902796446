import { useState, useEffect } from 'react';
import { useStore } from 'context/Store';
import Help from 'common/components/Registration/Help';
import { specialtiesIdsAndTitle } from 'common/utils/role';

const ServiceProviderInfo = ({ register, watch, isValid, t, handleTab }) => {
  const [checkboxError, setCheckboxError] = useState(false);

  const [state] = useStore();
  const { specialty_categories, specialties, industries, certifications } = state;

  const specialtiesIdsAndNames = specialtiesIdsAndTitle(specialties);

  const checkboxCount = Object.values(watch('professionalData.specialty')).filter(
    entry => entry
  ).length;

  useEffect(() => {
    setCheckboxError(checkboxCount > 3);
  }, [checkboxCount]);

  return (
    <div className="details__container">
      <h3 className="details__title">{t('yourSpecialty')}</h3>
      <p className="details__comment">{t('byAdding')}</p>

      <h4 className="details__subtitle">{t('specialtyTitle')}</h4>

      <div className="details__specialties">
        {specialty_categories
          .sort((a, b) => a.sort_order - b.sort_order)
          .map(category => {
            return (
              <div className="capabilities__block" key={category.id}>
                <div className="mb-20px" key={category.id}>
                  <h5 className="capabilities__subtitle">{category.name}</h5>
                  {category.specialties.map(specialtyId => (
                    <div className="user__item" key={specialtyId}>
                      <input
                        {...register(`professionalData.specialty.${specialtyId}`)}
                        name={`professionalData.specialty.${specialtyId}`}
                        type="checkbox"
                      />
                      <label>{specialtiesIdsAndNames[specialtyId]}</label>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
      {checkboxError && <p className="profile__error">{t('checkboxError')}</p>}

      <h5 className="details__subtitle">{t('industryExperience')}</h5>
      <div className="details__experience">
        {industries.length
          ? industries.map(industry => {
              return (
                <div className="user__item" key={industry.id}>
                  <input
                    {...register(`professionalData.industry.${industry.id}`)}
                    name={`professionalData.industry.${industry.id}`}
                    type="checkbox"
                  />
                  <label>{industry.name}</label>
                </div>
              );
            })
          : null}
      </div>

      <h5 className="details__subtitle">{t('certifications')}</h5>
      <div className="details__certifications">
        {certifications.length
          ? certifications.map(certification => {
              return (
                <div className="user__item" key={certification.id}>
                  <input
                    {...register(`professionalData.certification.${certification.id}`)}
                    name={`professionalData.certification.${certification.id}`}
                    type="checkbox"
                  />
                  <label>{certification.name}</label>
                </div>
              );
            })
          : null}
      </div>

      <div className="details__footer">
        <Help
          text={t('problem')}
          link="mailto:directory@crowe.org?Subject=Web Portal Problems"
          icon="fas fa-question-circle"
        />
        <div className="details__actions">
          <button
            className="registration__btn registration__btn--disabled  registration__btn--previous details__action"
            onClick={() => handleTab(2)}
          >
            {t('previous')}
          </button>
          <button
            type="submit"
            label={t('completeRegistration')}
            disabled={!isValid || checkboxError}
            className={`registration__btn--complete ${
              !isValid || checkboxError
                ? 'registration__btn--disabled'
                : 'registration__btn--enabled'
            }`}
          >
            {t('complete')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderInfo;
