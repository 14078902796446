import MemberCard from 'common/components/Directory/Cards/MemberCard';

const StaffList = ({ staff }) => {
  return (
    <div className="staff__container">
      {staff &&
        staff
          .sort((a, b) => a.contact_last_name.localeCompare(b.contact_last_name))
          .map(contact => (
            <MemberCard
              key={contact.user_id}
              id={contact.user_id}
              first_name={contact.contact_first_name}
              last_name={contact.contact_last_name}
              profile_photo_url={contact.contact_photo_url}
              email={contact.contact_email}
              network_roles={contact.network_roles}
            />
          ))}
    </div>
  );
};

export default StaffList;
