import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'context/Store';
import { shortIdToTitleHandler } from 'common/utils/role';
import { multiCapitalize } from 'common/utils/strings';

const MemberCard = ({
  id,
  first_name,
  last_name,
  profile_photo_url,
  firm,
  country,
  email,
  network_roles,
}) => {
  const [state] = useStore();

  const { network_roles: storedRoles } = state;

  const navigate = useNavigate();

  const handleLink = () => {
    navigate(`/directory/members/${id}`);
  };

  const roles = useMemo(() => {
    return shortIdToTitleHandler(network_roles, storedRoles);
  }, [network_roles, storedRoles]);

  return (
    <div className="directory__card">
      <div onClick={handleLink} className="contact-card__container">
        {profile_photo_url ? (
          <div className="contact-card__img">
            <img src={profile_photo_url} alt={`${first_name} ${last_name}`} />
          </div>
        ) : (
          <i className="fas fa-user-circle fs-60px text-secondary" />
        )}
        <div className="d-flex-col mx-10px">
          <p className="contact-card__name">{multiCapitalize(`${first_name} ${last_name}`)}</p>
          {!!roles.length &&
            roles.map((role, index) => (
              <li key={index} className="contact-card__item">
                {role}
              </li>
            ))}

          {country && (
            <div className="contact-card__country">
              <p className="contact-card__role fw-600">{country}</p>
            </div>
          )}
          {firm && <p className="contact-card__item">{firm}</p>}
          {email && (
            <div className="contact-card__email">
              <i className="far fa-envelope" />
              <a className="card__contact ellipsis" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
