import { useState, useEffect } from 'react';
import Committee from 'common/components/Committee/Committee';

const CommitteeList = ({ committeeList }) => {
  const [isOpen, setIsOpen] = useState('');
  const { title, committees } = committeeList.fields;

  useEffect(() => {
    document.title = `Crowe Global - ${title}`;
  }, [title]);
  return (
    <div className="committees">
      {committees?.length && (
        <>
          {committees.length > 1 && <h1 className="committees__title">{title}</h1>}
          <div className="accordion committees__accordion" id="committeesAccordion">
            {committees.map(committee => (
              <Committee
                key={committee.sys.id}
                id={committee.sys.id}
                committee={committee}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isUnique={committees.length == 1}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CommitteeList;
