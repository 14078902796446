import { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { roleIdsToNames, roleIdToName } from 'common/utils/role';
import { useStore } from 'context/Store';
import { getFirms } from 'services/firm';
import { sortByValue } from 'common/utils/sort';
import Help from 'common/components/Registration/Help';
import { useLocation } from 'react-router-dom';

const MemberDirectoryFilters = ({
  filters,
  firmRoles,
  networkRoles,
  specialtyCategories,
  specialties,
  industries,
  count,
  handleChange,
  error,
  isAdminSection,
}) => {
  const [state, dispatch] = useStore();
  const { countries, cities, firms } = state;

  const [firmOptions, setFirmOptions] = useState();

  const { country, city } = filters;

  const { t } = useTranslation('filters');

  const location = useLocation();

  const handleClear = () => {
    handleChange();
  };

  const networkRoleOptions = sortByValue(networkRoles, 'name').map(role => ({
    label: role.name,
    value: role.id,
  }));
  const firmRoleOptions = sortByValue(firmRoles, 'name').map(role => ({
    label: role.name,
    value: role.id,
  }));

  const industryOptions = industries.map(industry => ({
    label: industry.name,
    value: industry.id,
  }));
  const specialtyCategoryOptions = sortByValue(specialtyCategories, 'name').map(category => ({
    label: category.name,
    value: category.id,
  }));

  const selectedSpecialtyCategory = filters.specialty_category;

  const specialtiesOptions = sortByValue(specialties, 'name')
    .filter(specialty => specialty.category_id === selectedSpecialtyCategory)
    .map(specialty => ({
      label: specialty.name,
      value: specialty.id,
    }));

  const statusOptions = [
    { label: 'Approved', value: 'approved' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'Rejected', value: 'rejected' },
  ];

  const countryOptions = Object.keys(countries).map(country => ({
    label: country,
    value: country,
  }));

  const cityOptions = country
    ? countries[country].map(city => ({ label: city, value: city }))
    : Object.keys(cities).map(city => ({ label: city, value: city }));

  const firmOptionsHandler = async () => {
    try {
      let options = await getFirms(0, { country });
      return setFirmOptions(
        sortByValue(options.firms, 'name').map(firm => ({
          label: firm.name,
          value: firm.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleURLParameters = () => {
    const search = location.search?.split('?q=')[1];
    if (search) {
      dispatch({
        type: 'SET_MEMBER_FILTERS',
        payload: { ...filters, name: search.replace(/%20/g, ' ') },
      });
    }
  };

  useEffect(() => {
    handleURLParameters();
  }, [location]);

  useEffect(() => {
    if (city) {
      return setFirmOptions(cities[city].sort().map(firm => ({ label: firm, value: firm })));
    }
    setFirmOptions(
      sortByValue(firms, 'name').map(firm => ({ label: firm.name, value: firm.name }))
    );
  }, [firms, city]);

  useEffect(() => {
    if (country && !city) {
      firmOptionsHandler();
    }
  }, [country, city]);

  return (
    <div className="d-flex-col pb-10px">
      <div className="directory__help">
        <Help
          text="Enter text or select from each field below to find who you are looking for."
          icon="fa-solid fa-circle-info"
        />
      </div>
      <div className="d-flex justify-content-between px-20px filters__container">
        <div className="d-flex-col justify-content-between filters__column">
          <div className="d-flex-col">
            <label className="filters__label">{t('findProfessional')}</label>
            <input
              className="form-control filters__input mt-6px"
              type="text"
              placeholder="Enter Name"
              value={filters.name || ''}
              onChange={e => handleChange('name', { value: e.target.value })}
            />
          </div>
        </div>
        <div className="filters__column">
          <label className="filters__label">{t('filterBy')}: </label>
          <div className="filters__select">
            <Select
              isClearable
              options={countryOptions}
              placeholder="Country"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={e => handleChange('country', e)}
              value={country ? { value: country, label: country } : ''}
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              options={cityOptions}
              placeholder="City"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={e => handleChange('city', e)}
              value={city ? { value: city, label: city } : null}
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              options={firmOptions}
              placeholder="Firm"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={e => handleChange('firm', e)}
              value={filters.firm ? { value: filters.firm, label: filters.firm } : null}
            />
          </div>
          {!isAdminSection && (
            <div className="filters__select">
              <Select
                isClearable
                options={networkRoleOptions}
                placeholder={t('selectNetworkRoles')}
                className="react-select-container"
                classNamePrefix="react-select"
                isMulti
                onChange={e => handleChange('network_roles', e)}
                value={
                  filters.network_roles ? roleIdsToNames(filters.network_roles, networkRoles) : null
                }
              />
            </div>
          )}
          {isAdminSection && (
            <div className="filters__select">
              <Select
                isClearable
                options={statusOptions}
                placeholder="Status"
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={e => handleChange('userstatus', e)}
                value={
                  filters.userstatus
                    ? statusOptions.filter(
                        statusOption => statusOption.value === filters.userstatus
                      )
                    : statusOptions[0]
                }
              />
            </div>
          )}
        </div>
        <div className="filters__column filters__column--mt">
          {isAdminSection && (
            <div className="filters__select">
              <Select
                isClearable
                options={networkRoleOptions}
                placeholder={t('selectNetworkRoles')}
                className="react-select-container"
                classNamePrefix="react-select"
                isMulti
                onChange={e => handleChange('network_roles', e)}
                value={
                  filters.network_roles ? roleIdsToNames(filters.network_roles, networkRoles) : null
                }
              />
            </div>
          )}
          <div className="filters__select">
            <Select
              isClearable
              options={firmRoleOptions}
              placeholder={t('selectFirmRoles')}
              className="react-select-container"
              classNamePrefix="react-select"
              isMulti
              onChange={e => handleChange('firm_roles', e)}
              value={filters.firm_roles ? roleIdsToNames(filters.firm_roles, firmRoles) : null}
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              options={specialtyCategoryOptions}
              placeholder="Specialty Practice"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={e => handleChange('specialty_category', e)}
              value={
                filters.specialty_category
                  ? {
                      value: filters.specialty_category,
                      label: roleIdToName(filters.specialty_category, specialtyCategories),
                    }
                  : null
              }
            />
          </div>
          <div className="filters__select">
            <Select
              isClearable
              options={specialtiesOptions}
              placeholder="Sub-specialty Practice"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={e => handleChange('specialties', e)}
              value={
                filters.specialties
                  ? {
                      value: filters.specialties,
                      label: roleIdToName(filters.specialties, specialties),
                    }
                  : null
              }
            />
          </div>

          <div className="filters__select">
            <Select
              isClearable
              options={industryOptions}
              placeholder="Industry Experience"
              className="react-select-container"
              classNamePrefix="react-select"
              isMulti
              onChange={e => handleChange('industries', e)}
              value={filters.industries ? roleIdsToNames(filters.industries, industries) : null}
            />
          </div>
        </div>
      </div>
      <div className="filters__btn-container px-20px">
        {error && <span className="input__error fs-14px">{t('error')}</span>}
        <span>
          {count} {count === 1 ? 'Person' : 'People'}
        </span>
        <div className="btn btn-primary  filters__btn">
          <a onClick={handleClear}>
            <small>{t('clearAllFilters')}</small>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MemberDirectoryFilters;
