import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import DirectoryHeader from 'common/components/Directory/DirectoryHeader';
import Overview from './Overview';
import Offices from './Offices';
import StaffList from './StaffList';
import Capabilities from './Capabilities';
import Flag from '../Flag/Flag';
import pdf from 'assets/icons/svg/pdf.svg';

const FirmDetail = ({
  firm,
  offices,
  staff,
  keyContacts,
  capabilities,
  industries,
  vlpServices,
  country,
  country_code,
}) => {
  const [selected, setSelected] = useState('Overview');

  const { t } = useTranslation('firmProfile');

  const firmTypes = {
    Member: 'Member Firm',
    BA: 'Business Associate',
    'Subsidiary - Branded': 'Subsidiary',
    'Subsidiary - Non-Branded': 'Subsidiary',
  };

  const firmType = firmTypes[firm.firm_type] || firm.firm_type;

  const tabs = {
    components: {
      Overview: <Overview firm={firm} contacts={keyContacts} />,
      Locations: <Offices firm={firm} offices={offices} />,
      Staff: staff.length ? <StaffList staff={staff} /> : null, // TODO merge this component with Staff.jsx component
      Capabilities: (
        <Capabilities
          capabilities={capabilities}
          industries={industries}
          vlpServices={vlpServices}
        />
      ),
    },
    names: [{ title: 'Overview' }],
  };

  if (offices.length) {
    tabs.names = [...tabs.names, { title: 'Locations' }];
  }
  if (staff.length) {
    tabs.names = [...tabs.names, { title: 'Staff' }];
  }

  if (
    !!capabilities[0].category ||
    (industries.length && !!industries[0]) ||
    (vlpServices.length && !!vlpServices[0])
  ) {
    tabs.names = [...tabs.names, { title: 'Capabilities' }];
  }

  const headOffice = find(offices, o => o.is_headquarters);

  return (
    <div className="detail__container">
      <DirectoryHeader />
      <div className="detail__widgets">
        <div className="detail__widget-container--large">
          <div className="widget-todolist rounded position-relative p-20px mb-20px">
            <h1 className="detail__title ellipsis-sl">{firm.name}</h1>
            <div className="row-center mb-20px">
              <div className="card__flag">
                <Flag country={country_code} />
              </div>
              <p className="my-0 mx-6px fs-16px ellipsis-sl">
                {country} • {firm.region} • {firmType}
              </p>
            </div>
            <div className="detail__tab-container">
              {tabs.names.map(tab => (
                <p
                  key={tab.title}
                  className={`btn btn-primary fw-600 cursor-pointer detail__tab ${
                    selected === tab.title ? 'active' : ''
                  }`}
                  onClick={() => setSelected(tab.title)}
                >
                  {tab.title}
                </p>
              ))}
            </div>
          </div>
          {tabs.components[selected]}
        </div>
        {selected !== 'Locations' && (
          <div className="detail__widget-container--small">
            {firm.brochure_url && (
              <div className="col-md-12 p-0">
                <div className="widget-todolist rounded position-relative detail__widget">
                  <div className="row-center">
                    <a target="_blank" href={firm.brochure_url}>
                      <img className="resource__svg" src={pdf} />
                    </a>
                    <div className="d-flex-col mx-10px">
                      <h3 className="widget__description fw-600 lh-sm m-0">{t('downloads')}</h3>
                      <a
                        className="text-decoration-none lh-sm widget__description"
                        target="_blank"
                        href={firm.brochure_url}
                      >
                        <p className="widget__description ellipsis-sl">{t('firmBrochure')}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12 p-0">
              <div className="widget-todolist rounded position-relative detail__widget">
                <div className="office__header">
                  {country_code && (
                    <div className="card__flag">
                      <Flag country={country_code} />
                    </div>
                  )}
                  <div className="office__general">
                    <p className="office__country">{country}</p>
                    <p className="detail__firm-name ellipsis-sl">{firm.name}</p>
                  </div>
                </div>
                {headOffice && (
                  <div className="office__data">
                    <h3 className="office__head-office">{t('headOffice')}</h3>
                    <p className="office__detail">{firm.name}</p>

                    <p className="office__detail">{headOffice.address} </p>

                    <p className="office__detail">{headOffice.city}</p>
                    <p className="office__detail">{country}</p>
                    <p className="office__detail">{headOffice.postal_code}</p>
                    {headOffice.hours && (
                      <div className="d-flex pt-10px">
                        <i className="far fa-clock detail__icon--phone" />
                        <p className="fs-16px my-0 lh-1">{headOffice.hours}</p>
                      </div>
                    )}
                  </div>
                )}

                {headOffice?.phone_number && (
                  <div className="d-flex pt-10px">
                    <i className="fas fa-phone detail__icon--phone" />
                    <p className="fs-16px my-0 lh-1">{`${t('office')} ${
                      headOffice.phone_number_country_code
                        ? headOffice.phone_number_country_code
                        : ''
                    } ${headOffice.phone_number}`}</p>
                  </div>
                )}
                {headOffice?.email && (
                  <div className="row-center pt-10px">
                    <i className="far fa-envelope fs-16px detail__icon--globe" />
                    <a
                      className="detail__url ellipsis-sl"
                      href={`mailto:${headOffice.email}`}
                      target="_blank"
                    >
                      {headOffice.email}
                    </a>
                  </div>
                )}
                {firm.website_url && (
                  <div className="row-center pt-10px">
                    <i className="fas fa-globe fs-16px detail__icon--globe" />
                    <a className="detail__url ellipsis-sl" href={firm.website_url} target="_blank">
                      {firm.website_url}
                    </a>
                  </div>
                )}
                <div className="detail__social col-md-12 ">
                  {firm.linkedin?.includes('http') && (
                    <a href={firm.linkedin} target="_blank" className="pt-10px">
                      <i className={`fab fa-linkedin detail__icon--social`} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FirmDetail;
