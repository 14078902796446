import { post } from 'common/api';

export const postSocial = async body => {
  try {
    const res = await post('/social', body, { dataType: 'fileUpload' });
    return res;
  } catch (error) {
    return error;
  }
};
