import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateFirm } from 'services/firm';
import { isValidLinkedIn, isValidUrl, fileSize } from 'common/utils/validations';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from 'common/constants/toast';
import BouncingButton from 'common/components/Button/BouncingButton';
import Help from 'common/components/Registration/Help';

const FirmProfileEdit = ({
  firm,
  industries,
  specialtyCategories,
  specialtiesIdsAndNames,
  vlpServicesIdsAndNames,
  vlpServices,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('firmEdit');
  const { t: gt } = useTranslation('global');
  const { t: vt } = useTranslation('validations');

  const handleInitialValues = () => {
    if (firm.firm) {
      const data = firm.firm;

      data.specialties = {};
      data.industries = {};
      data.vlp_services = {};

      firm.capabilities
        ?.flatMap(category => category.specialties.map(id => id))
        .map(specialtyId => (data.specialties = { ...data.specialties, [specialtyId]: true }));

      firm.industries.map(id => {
        if (id) return (data.industries = { ...data.industries, [id]: true });
      });

      firm.vlp_services.map(service => {
        if (service) {
          return (data.vlp_services = { ...data.vlp_services, [service]: true });
        }
      });

      return {
        brochure: [],
        ...firm.firm,
        ...data,
      };
    }
  };

  const { register, handleSubmit, trigger, watch, setValue, formState } = useForm({
    mode: 'all',
    defaultValues: handleInitialValues(),
  });
  const { errors, isValid } = formState;

  const handleUpdate = async data => {
    setLoading(true);
    try {
      await updateFirm(firm.firm.id, data);
      toast.success(t('firmUpdated'), TOAST_CONFIG);
      setLoading(false);
    } catch (err) {
      toast.error(gt('errorMessage'), TOAST_CONFIG);
      setLoading(false);
    }
  };

  const handleClearBrochure = () => {
    setValue('brochure', []);
    setValue('brochure_file_name', '');
    setValue('brochure_url', '');
    trigger('brochure');
  };
  const brochure_file_name = watch('brochure_file_name');
  const brochure = watch('brochure');

  useEffect(() => {
    trigger();
  }, []);

  return (
    <form onSubmit={handleSubmit(handleUpdate)} className="admin__tab-container rounded" noValidate>
      <div className="admin__form">
        <div className="admin__header">
          <div className="admin__btn-container">
            <Link to={`/directory/firms/${firm?.firm?.id}`} className="admin__btn-disabled">
              {t('viewProfile')}
            </Link>
            <BouncingButton
              className={`${isValid ? 'admin__btn' : 'admin__btn-disabled'}`}
              loading={loading}
              type="submit"
              disabled={!isValid}
            >
              {loading ? t('savingChanges') : t('saveChanges')}
            </BouncingButton>
          </div>
          <div className="d-flex mt-20px">
            <i className="fas fa-info-circle account__icon mt4px" />
            <p className="admin__comment">{t('infoText')}</p>
          </div>
        </div>

        <div className="admin__inputs">
          <div className="admin__about">
            <label>{t('aboutFirm')}</label>
            <textarea {...register('description')} className="form-control" name="description" />
          </div>

          <div className="admin__columns">
            <div className="admin__column">
              <label>{t('firmWebsite')}</label>
              <input
                {...register('website_url', {
                  validate: { isValidUrl: url => isValidUrl(url, vt) },
                })}
                name="website_url"
                className={`form-control input__container ${
                  errors.website_url ? 'form__input--error' : ''
                }`}
                placeholder={t('firmWebsitePlaceholder')}
              />
              <span className="input__error">{errors.website_url?.message}</span>
              <label>{t('firmBrochure')}</label>
              <div className="upload">
                {!!brochure.length || brochure_file_name ? (
                  <button
                    className="profile__btn profile__btn--brochure m-0"
                    onClick={handleClearBrochure}
                  >
                    Remove Firm Brochure
                  </button>
                ) : (
                  <label htmlFor="file-upload" className="profile__btn m-0">
                    Add Firm Brochure
                  </label>
                )}
                <input
                  {...register('brochure', {
                    validate: { fileSize: value => fileSize(value, vt, 'brochure') },
                  })}
                  type="file"
                  name="brochure"
                  id="file-upload"
                  className="hidden"
                />
                <span className="input__error">{errors.brochure?.message}</span>
                {(!!brochure.length || brochure_file_name) && (
                  <div>
                    <i className="fas fa-file-alt admin__icon--file" />
                    <label>Firm Brochure</label>
                  </div>
                )}
              </div>
              <br />
              <Help text={t('sizeTip')} icon="fas fa-exclamation-circle" />
            </div>

            <div className="admin__column">
              <label>{t('linkedin')}</label>
              <input
                {...register('linkedin', {
                  validate: { isValidLinkedIn: url => isValidLinkedIn(url, vt) },
                })}
                name="linkedin"
                placeholder={t('linkedinPlaceholder')}
                className={`form-control input__container ${
                  errors.linkedin ? 'form__input--error' : ''
                }`}
              />
              <span className="input__error">{errors.linkedin?.message}</span>
            </div>
          </div>
        </div>
        {
          <div className="admin__section">
            <h2 className="capabilities__subtitle mt-30px">{t('virtualLaborPool')}</h2>
            <div className="admin__checklist">
              {vlpServices.map(service => (
                <div className="form__checkbox" key={service.id}>
                  <input
                    {...register(`vlp_services.${service.id}`)}
                    className="form__checkbox-input"
                    type="checkbox"
                    name={`vlp_services.${service.id}`}
                  />
                  <span className="checkbox">{vlpServicesIdsAndNames[service.id]}</span>
                </div>
              ))}
            </div>
          </div>
        }
        <div className="admin__section">
          <h2 className="admin__subtitle">{t('specialtyPractice')}</h2>
          <div className="admin__checklist">
            {specialtyCategories
              .sort((a, b) => a.sort_order - b.sort_order)
              .map(category => {
                return (
                  <div className="capabilities__block" key={category.id}>
                    <div className="mb-20px">
                      <h5 className="capabilities__subtitle">{category.name}</h5>
                      {!!category.specialties.length &&
                        category.specialties.map(specialty => (
                          <div key={specialty} className="form__checkbox">
                            <input
                              {...register(`specialties.${specialty}`)}
                              className="form__checkbox-input"
                              type="checkbox"
                              name={`specialties.${specialty}`}
                              id={specialty}
                            />
                            <span className="checkbox" htmlFor={specialty}>
                              {specialtiesIdsAndNames[specialty]}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="admin__section">
          <h2 className="capabilities__subtitle mt-40px">{t('industryExperience')}</h2>

          <div className="admin__checklist">
            {!!industries.length &&
              industries.map(industry => {
                return (
                  <div key={industry.id} className="form__checkbox">
                    <input
                      {...register(`industries.${industry.id}`)}
                      className="form__checkbox-input"
                      type="checkbox"
                      name={`industries.${industry.id}`}
                    />
                    <span className="checkbox">{industry.name}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </form>
  );
};

export default FirmProfileEdit;
