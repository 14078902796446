import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileInfo from './ProfileInfo';
import { getFirm } from 'services/firm';
import ServiceProviderInfo from './ServiceProviderInfo';
import AccountInfo from 'common/components/UserProfileForm/AccountInfo';
import RolesInfo from './RolesInfo.jsx';
import { useTranslation } from 'react-i18next';
import { multiCapitalize } from 'common/utils/strings';
import { useForm } from 'react-hook-form';
import BouncingButton from 'common/components/Button/BouncingButton';

const UserProfile = ({ user, update, loading, removePhoto, removeCV, resumeFile }) => {
  const { t } = useTranslation('userProfile');

  const navigate = useNavigate();

  const handleInitialValues = () => {
    let obj = { specialty: {}, networkRole: {}, firmRole: {}, industry: {}, certification: {} };

    user.specialties?.forEach(id => {
      obj.specialty[id] = true;
    });

    user.network_roles?.forEach(id => {
      obj.networkRole[id] = true;
    });

    user.firm_roles?.forEach(id => {
      obj.firmRole[id] = true;
    });

    user.industries?.forEach(id => {
      obj.industry[id] = true;
    });
    user.certifications?.forEach(id => {
      obj.certification[id] = true;
    });

    return {
      ...user,
      professionalData: { officeId: user.office_id, ...obj },
    };
  };

  const { register, control, handleSubmit, trigger, formState, watch, setValue } = useForm({
    mode: 'all',
    defaultValues: handleInitialValues(),
  });

  const { errors, isValid } = formState;

  const [firm, setFirm] = useState(null);
  const [checkboxError, setCheckboxError] = useState(false);

  const handleFirm = async () => {
    try {
      const data = await getFirm(user.firm_id);
      setFirm(data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    handleFirm();
  }, [user]);

  return (
    <>
      <form className="user__container rounded" onSubmit={handleSubmit(update)} noValidate>
        <div className="user__header">
          <div className="user__header--left">
            <div className="user__comment--currently">
              <h2 className="user__title--personal">
                Personal Profile:
                {multiCapitalize(
                  ` ${user.salutation ? user.salutation : ''} ${user.first_name} ${user.last_name}`
                )}
              </h2>
            </div>
            <p className="user__comment--star">* Denotes required field</p>
          </div>

          <div className="user__header--right">
            <button
              onClick={() => navigate(`/directory/members/${user.id}`)}
              className="user__btn-secondary"
            >
              {t('cancel')}
            </button>

            <BouncingButton
              className={
                !isValid || checkboxError
                  ? 'user__btn-secondary user__btn-secondary--not-allowed'
                  : 'user__btn'
              }
              loading={loading}
              type="submit"
              disabled={!isValid || checkboxError}
            >
              {loading ? t('savingChanges') : t('saveChanges')}
            </BouncingButton>
          </div>
        </div>

        <AccountInfo
          user={user}
          register={register}
          control={control}
          watch={watch}
          t={t}
          errors={errors}
          trigger={trigger}
        />

        {firm && (
          <ProfileInfo
            trigger={trigger}
            register={register}
            watch={watch}
            errors={errors}
            setValue={setValue}
            firm={firm}
            user={user}
            removePhoto={removePhoto}
            removeCV={removeCV}
            resumeFile={resumeFile}
          />
        )}

        <RolesInfo
          register={register}
          control={control}
          watch={watch}
          trigger={trigger}
          errors={errors}
          firmRoles={user.firm_roles}
        />

        <ServiceProviderInfo
          register={register}
          watch={watch}
          trigger={trigger}
          setCheckboxError={setCheckboxError}
        />

        <div className="user__header--right">
          <button
            onClick={() => navigate(`/directory/members/${user.id}`)}
            className="user__btn-secondary"
          >
            {t('cancel')}
          </button>

          <BouncingButton
            className={
              !isValid || checkboxError
                ? 'user__btn-secondary user__btn-secondary--not-allowed'
                : 'user__btn'
            }
            loading={loading}
            type="submit"
            disabled={!isValid || checkboxError}
          >
            {loading ? t('savingChanges') : t('saveChanges')}
          </BouncingButton>
        </div>
      </form>
    </>
  );
};

export default UserProfile;
