import { Link, useNavigate } from 'react-router-dom';
import Flag from 'common/components/Flag/Flag';

const FirmCard = ({ id, name, country, country_code, firm_type, t }) => {
  const navigate = useNavigate();

  const handleFirmType = () => {
    if (firm_type === 'Member') {
      return 'Member Firm';
    } else if (firm_type === 'BA') {
      return 'Business Associate';
    } else if (firm_type === 'Subsidiary - Branded' || firm_type === 'Subsidiary - Non-Branded') {
      return 'Subsidiary';
    } else {
      return firm_type;
    }
  };

  return (
    <div
      className="card__container"
      onClick={() => {
        navigate(`/directory/firms/${id}`);
      }}
    >
      <div className="col-md-2 card__column--first">
        <div className="card__flag">
          <Flag country={country_code} />
        </div>
        <p className="card__country">{country}</p>
      </div>
      <div className="card__column--second">
        <p className="m-0 fs-18px fw-700 cursor-pointer ellipsis-sl">{name}</p>
        <p className="fs-16px m-0">{handleFirmType()}</p>
      </div>

      <div className="btn btn-primary col-md-2 card__btn--w112">
        <Link to={`/directory/firms/${id}`}>
          <small className="fs-16px text-white fw-light">Firm Details</small>
        </Link>
      </div>
    </div>
  );
};

export default FirmCard;
