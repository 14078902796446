import { put } from 'common/api';

export const updateOffice = async (id, params) => {
  try {
    const data = await put(`/offices/${id}`, params);
    return data;
  } catch (err) {
    return err;
  }
};
