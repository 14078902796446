import { get } from '../common/api';

export const getResourcesByTag = async (
  tags = '',
  type = '',
  keyword = '',
  offset = 0,
  sort = ''
) => {
  try {
    tags = Array.isArray(tags) ? tags.map(obj => obj.sys.id).join(',') : tags;
    const res = await get(
      `/resources?tags=${tags}&type=${type}&q=${keyword}&offset=${offset}&sort=${sort}`
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getResourceById = async slug => {
  try {
    const { items } = await get(`/resources/${slug}`);
    return items;
  } catch (error) {
    return error;
  }
};
