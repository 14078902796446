import { useTranslation } from 'react-i18next';
import { useStore } from 'context/Store';
import { longIdToTitleHandler } from 'common/utils/role';
import { specialtiesIdsAndTitle, sortCapabilities } from 'common/utils/role';

const Capabilities = ({ capabilities, industries, vlpServices }) => {
  const [state] = useStore();
  const {
    industries: storedIndustries,
    specialty_categories,
    specialties,
    vlpServices: storedVLPServices,
  } = state;

  const { t } = useTranslation('capabilities');

  const specialtiesIdsAndNames = specialtiesIdsAndTitle(specialties);
  const categoriesIdsAndNames = specialtiesIdsAndTitle(specialty_categories);

  const sortedCapabilities = sortCapabilities(capabilities, specialty_categories);

  const checkedVlps = !!vlpServices.length && !!vlpServices[0];
  const checkedIndustries = !!industries.length && !!industries[0];
  const checkedCaps = !!capabilities.length && !!capabilities[0].category;

  return (
    <div className="capabilities__container">
      {checkedVlps && (
        <div className="capabilities__list">
          <h2 className="capabilities__subtitle">{t('vlp')}</h2>
          <div className="capabilities__inner">
            {longIdToTitleHandler(vlpServices, storedVLPServices).map((service, i) => {
              return (
                <p key={i} className="fs-16px m-0">
                  {service}
                </p>
              );
            })}
          </div>
        </div>
      )}

      {checkedCaps && (
        <div className={`capabilities__inner ${checkedVlps ? 'border-top pt-20px' : ''}`}>
          {sortedCapabilities.map(category => {
            return (
              <div className="capabilities__block" key={category}>
                <p className="capabilities__subtitle">{categoriesIdsAndNames[category.category]}</p>
                <div className="capabilities__list">
                  {!!category.specialties.length &&
                    category.specialties.map(specialty => (
                      <p className="fs-16px m-0">{specialtiesIdsAndNames[specialty]}</p>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {checkedIndustries && (
        <div
          className={`capabilities__list ${checkedVlps || checkedCaps ? 'pt-20px border-top' : ''}`}
        >
          <h2 className="capabilities__subtitle">{t('industries')}</h2>
          <div className="capabilities__inner">
            {longIdToTitleHandler(industries, storedIndustries).map((industry, i) => {
              return (
                <p key={industry} className="fs-16px m-0">
                  {industry}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Capabilities;
