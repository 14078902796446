import { required, passwordMatch, validPasswordAndRequirements } from '../../utils/validations';
import { useForm } from 'react-hook-form';
import BouncingButton from 'common/components/Button/BouncingButton';
import { useTranslation } from 'react-i18next';

export const ResetPasswordForm = ({ handleResetPassword, loading, t }) => {
  const { t: vt } = useTranslation('validations');

  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
  });

  const { isValid, errors } = formState;

  const password = watch('password');

  return (
    <div className="forgot__form rounded">
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <label>{t('password')}</label>
        <input
          {...register('password', {
            validate: {
              required: value => required(value, vt),
              validPassword: value => validPasswordAndRequirements(value, vt),
            },
          })}
          name="password"
          type="password"
          className={`form-control input__container ${errors.password ? 'form__input--error' : ''}`}
          placeholder={t('password')}
        />
        {errors.password && <span className="input__error">{errors.password.message}</span>}
        <br />
        <label>{t('passwordConfirm')}</label>
        <input
          {...register('passwordConfirm', {
            validate: {
              required: value => required(value, vt),
              match: value => passwordMatch(value, password, vt),
            },
          })}
          name="passwordConfirm"
          type="password"
          className={`form-control input__container ${
            errors.passwordConfirm ? 'form__input--error' : ''
          }`}
          placeholder={t('password')}
        />
        {errors.passwordConfirm && (
          <span className="input__error">{errors.passwordConfirm.message}</span>
        )}
        <BouncingButton
          className={`forgot__btn ${!isValid ? 'forgot__btn--disabled' : ''}`}
          type="submit"
          disabled={!isValid}
          loading={loading}
        >
          {loading ? t('submitting') : t('submit')}
        </BouncingButton>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
