import { post } from 'common/api';

export const uploadFiles = async params => {
  try {
    const files = await post('/files/upload', params, { dataType: 'fileUpload' }, true);
    return files;
  } catch (err) {
    throw new Error(err);
  }
};
