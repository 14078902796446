import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './registerServiceWorker';

import App from 'App';

import { BrowserRouter as Router } from 'react-router-dom';

import './i18n';

import { StoreProvider } from './context/Store';
import { initialState, reducer } from './store/reducer';

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <StoreProvider initialState={initialState} reducer={reducer}>
      <Router>
        <App />
      </Router>
    </StoreProvider>
  </StrictMode>
);

serviceWorkerRegistration.unregister();
