import { useTranslation } from 'react-i18next';

const Message = ({ handleClose, url }) => {
  const { t } = useTranslation('resource');

  return (
    <div className="toast__container">
      <p className="toast__text">{t('toast')}</p>
      <div className="toast__actions">
        <button
          className="toast__action toast__action--accept"
          onClick={() => (window.location = url)}
        >
          {t('accept')}
        </button>
        <button className="toast__action toast__action--decline" onClick={handleClose}>
          {t('decline')}
        </button>
      </div>
    </div>
  );
};

export default Message;
