import { useState, useEffect, useMemo } from 'react';
import { useStore } from 'context/Store';
import Select from 'react-select';
import { salutationOptions } from 'common/constants/forms';
import {
  required,
  email,
  validCountryCode,
  codeRequired,
  phoneRequired,
  containsOnlyNumbers,
} from 'common/utils/validations';
import { Controller } from 'react-hook-form';
import Flag from 'common/components/Flag/Flag';
import { getFirm } from 'services/firm';
import Help from 'common/components/Registration/Help';
import { useTranslation } from 'react-i18next';

const AccountInfo = ({ user, register, control, errors, watch, trigger, t }) => {
  const { t: vt } = useTranslation('validations');

  const [state] = useStore();
  const { hasAdminPermission, hasFirmAdminPermission } = state;

  const [offices, setOffices] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const officeDirectCode = watch('office_direct_phone_country_code');
  const officeDirectPhone = watch('office_direct_phone');
  const mobileCode = watch('mobile_phone_country_code');
  const mobilePhone = watch('mobile_phone');
  const firm = watch('firm');

  const handleOfficeOptions = async () => {
    const data = await getFirm(user.firm_id);
    setOffices(
      data.offices.map(office => ({
        value: office.id,
        label: `${office.country} / ${user.firm} / ${office.address}${
          !!office.address ? ',' : ''
        } ${office.city}${!!office.city ? ',' : ''} ${office.postal_code}${
          !!office.postal_code ? ',' : ''
        } ${office.country}`,
      }))
    );
  };

  useEffect(() => {
    handleOfficeOptions();
  }, [user]);

  useEffect(() => {
    trigger();
  }, [officeDirectCode, officeDirectPhone, mobileCode, mobilePhone]);

  return (
    <div className="user__data">
      {(hasAdminPermission || hasFirmAdminPermission) && !!offices.length && (
        <div className="user__input mb-30px">
          <div className="d-flex">
            <div className="card__flag">
              <Flag country={user.country_code} />
            </div>
            <p className="user__firm-name">{firm}</p>
          </div>
          <div className="mb-10px">
            <Controller
              name="professionalData.officeId"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field, ref }) => (
                <Select
                  ref={ref}
                  options={offices}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  defaultValue={offices.find(option => option.value === field.value)}
                  onChange={val => {
                    field.onChange(val.value);
                    setShowWarning(true);
                  }}
                />
              )}
            />
          </div>
          {showWarning && <Help text={t('verifyPhone')} icon="fas fa-info-circle" />}
          <input type="checkbox" {...register('office_care_of')} />
          <label htmlFor="office_care_of" className="user__label--care-of">
            {t('careOfLabel')}
          </label>
        </div>
      )}
      <div className="user__inputs">
        <div className="user__column">
          <div className="user__input">
            <label>{`${t('salutation')}*`}</label>
            <Controller
              name="salutation"
              control={control}
              rules={{ required: 'Required' }}
              render={({ field, ref }) => (
                <Select
                  ref={ref}
                  options={salutationOptions}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  defaultValue={salutationOptions.find(option => option.value === field.value)}
                  onChange={val => field.onChange(val.value)}
                />
              )}
            />
            <span className="input__error">{errors.salutation?.message}</span>
          </div>
          <div className="user__input">
            <label>{`${t('givenNames')}*`}</label>
            <input
              className={`form-control input__container ${
                errors.first_name ? 'form__input--error' : ''
              }`}
              {...register('first_name', { validate: { required: value => required(value, vt) } })}
              name="first_name"
              placeholder={t('firstName')}
            />
            <span className="input__error">{errors.first_name?.message}</span>
          </div>
          <div className="user__input">
            <label>{`${t('surname')}*`}</label>
            <input
              className={`form-control input__container ${
                errors.last_name ? 'form__input--error' : ''
              }`}
              {...register('last_name', { validate: { required: value => required(value, vt) } })}
              name="last_name"
              placeholder={t('lastName')}
            />
            <span className="input__error">{errors.last_name?.message}</span>
          </div>
        </div>

        <div className="user__column">
          <div className="user__input">
            <label>{`${t('email')}*`}</label>
            <input
              className={`form-control input__container ${
                errors.email ? 'form__input--error' : ''
              }`}
              {...register('email', {
                validate: {
                  required: value => required(value, vt),
                  isEmail: value => email(value, vt),
                },
              })}
              name="email"
            />
            <span className="input__error">{errors.email?.message}</span>
          </div>

          <div className="user__multiple-field">
            <label>{t('directOfficeNumber')}</label>
            <div className="user__phones">
              <div className="user__input user__input--code mb-20px">
                <input
                  className={`form-control input__container ${
                    errors.office_direct_phone_country_code ? 'form__input--error' : ''
                  }`}
                  {...register('office_direct_phone_country_code', {
                    validate: {
                      isCountryCode: value => validCountryCode(value, vt),
                      codeRequired: () => codeRequired(officeDirectCode, officeDirectPhone, vt),
                    },
                  })}
                  name="office_direct_phone_country_code"
                  placeholder={t('countryCode')}
                />
                <span className="input__error">
                  {errors.office_direct_phone_country_code?.message}
                </span>
              </div>
              <div className="user__input user__input--phone mb-20px">
                <input
                  className={`form-control input__container ${
                    errors.office_direct_phone ? 'form__input--error' : ''
                  }`}
                  {...register('office_direct_phone', {
                    validate: {
                      phoneRequired: () => phoneRequired(officeDirectCode, officeDirectPhone, vt),
                      containsOnlyNumbers: phone => containsOnlyNumbers(phone, vt),
                    },
                  })}
                  name="office_direct_phone"
                  placeholder={`${t('directOfficeNumber')}`}
                />
                <span className="input__error">{errors.office_direct_phone?.message}</span>
              </div>
            </div>
          </div>

          <div className="user__multiple-field">
            <label>{t('mobileNumber')}</label>
            <div className="user__phones">
              <div className="user__input user__input--code">
                <input
                  className={`form-control input__container ${
                    errors.mobile_phone_country_code ? 'form__input--error' : ''
                  }`}
                  {...register('mobile_phone_country_code', {
                    validate: {
                      isCountryCode: value => validCountryCode(value, vt),
                      codeRequired: () => codeRequired(mobileCode, mobilePhone, vt),
                    },
                  })}
                  name="mobile_phone_country_code"
                  placeholder={t('countryCode')}
                />
                <span className="input__error">{errors.mobile_phone_country_code?.message}</span>
              </div>
              <div className="user__input user__input--phone">
                <input
                  className={`form-control input__container ${
                    errors.mobile_phone ? 'form__input--error' : ''
                  }`}
                  {...register('mobile_phone', {
                    validate: {
                      phoneRequired: () => phoneRequired(mobileCode, mobilePhone, vt),
                      containsOnlyNumbers: phone => containsOnlyNumbers(phone, vt),
                    },
                  })}
                  name="mobile_phone"
                  placeholder={t('mobileNumber')}
                />
                <span className="input__error">{errors.mobile_phone?.message}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
