import { get } from '../common/api';

export const getArticlesPageData = async slug => {
  try {
    const { items } = await get(`/article_list_pages/${slug}`);
    return items;
  } catch (error) {
    return error;
  }
};

export const getResourcesPageData = async slug => {
  try {
    const { items } = await get(`/resource_list_pages/${slug}`);
    return items;
  } catch (error) {
    return error;
  }
};
