import moment from 'moment';
import * as momentTz from 'moment-timezone';

export const formatDate = date => date.format('MMMM D, YYYY');

export const formatDateWithTimezone = (date, timezone, format) =>
  momentTz.tz(date, timezone).format(format);

export const formatDateWeekDay = data => moment(data).format('dddd, D MMMM YYYY');

export const formatDateWithoutYear = date => date.format('MMMM D');

export const formatEventDate = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  if (start.isSame(end)) {
    return formatDate(start);
  }

  if (start.year() !== end.year()) {
    return `${formatDate(start)} - ${formatDate(end)}`;
  }

  return `${formatDateWithoutYear(start)} - ${formatDate(end)}`;
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getLastThreeYears = () => {
  const currentYear = new Date().getFullYear();
  return [currentYear.toString(), (currentYear - 1).toString(), (currentYear - 2).toString()];
};
