import { useState, useEffect } from 'react';
import DesksFilters from 'common/components/Directory/Filters/DesksFilters';
import { useGet } from 'hooks/useGet';
import { getDesks } from 'services/desks';
import DeskCard from 'common/components/Directory/Cards/DeskCard';
import { groupByDesk } from 'common/helpers/desks';
import NoResults from 'common/components/NoResults/NoResults';
import { useTranslation } from 'react-i18next';
import FirmCardSkeleton from 'common/components/Skeletons/FirmCardSkeleton';

const ForeignDesks = () => {
  const [filteredDesks, setFilteredDesks] = useState(null);

  const { t } = useTranslation('directory');

  const { data: users, isLoading, isSuccess } = useGet(getDesks);

  useEffect(() => {
    if (users) {
      setFilteredDesks(groupByDesk(users));
    }
  }, [users]);

  return (
    <div className="detail__widget-container--large">
      <DesksFilters items={users} setFilteredItems={setFilteredDesks} />
      {isLoading ? (
        <div className="directory__list mt-70px">
          <FirmCardSkeleton />
          <FirmCardSkeleton />
          <FirmCardSkeleton />
          <FirmCardSkeleton />
          <FirmCardSkeleton />
          <FirmCardSkeleton />
          <FirmCardSkeleton />
          <FirmCardSkeleton />
        </div>
      ) : (
        filteredDesks && (
          <>
            {!!filteredDesks.length && (
              <div className="desks__header">
                <div className="desks__col-1">
                  <p>Foreign Desk</p>
                </div>
                <div className="desks__col-2">
                  <p>Country</p>
                  <p>Member Firm</p>
                </div>
                <div className="desks__col-3">
                  <p>Contact</p>
                </div>
              </div>
            )}
            <div className="desks__container">
              {filteredDesks.length ? (
                filteredDesks.map(object =>
                  Object.values(object).map(users =>
                    users.map(user => (
                      <DeskCard
                        key={user.id}
                        id={user.id}
                        country={user.country}
                        countryCode={user.country_code}
                        firm={user.firm}
                        first_name={user.first_name}
                        last_name={user.last_name}
                        email={user.email}
                        desk={Object.keys(object)[0]}
                      />
                    ))
                  )
                )
              ) : (
                <NoResults
                  icon="icon-search"
                  title={t('noDesks')}
                  comment={t('tryDifferentFilters')}
                />
              )}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default ForeignDesks;
