import axios from 'axios';

export const getTerms = async () => {
  try {
    const { data } = await axios.get(`${process.env.CROWE_BASE_SERVER_URL}/terms_of_use`);
    return data.items[0];
  } catch (error) {
    return error;
  }
};
