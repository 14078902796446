import { useEffect, useState } from 'react';
import { HYVOR_WEBSITE_ID } from 'common/constants/hyvor';
import { isVideo } from 'common/helpers/file';
import { resourceIconHandler, fileButtonHandler } from 'common/helpers/file';
import { Comments } from '@hyvor/hyvor-talk-react';
import { constructHyvorSso } from 'common/helpers/hyvor';

const ResourceDetail = ({ resource }) => {
  const [url, setUrl] = useState('');
  const { media, description, title, resourceType } = resource.fields;

  const { id } = resource.sys;

  useEffect(() => {
    setUrl(resource.fields.url || media?.fields.file.url);
  }, [resource, media]);

  return (
    <>
      <div className="video__container">
        {isVideo(resourceType) ? (
          <>
            <h2 className="layout__title">{title}</h2>
            {url && (
              <iframe
                loading="lazy"
                src={url}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen; web-share"
                allowfullscreen
              ></iframe>
            )}
            <p className="video__description">{description}</p>
          </>
        ) : (
          <div className="page__content">
            <h2 className="layout__title">{title}</h2>
            <div className="resource__details">
              <a href={url} target="_blank">
                {resourceIconHandler(resourceType)}
              </a>
              <p>{description}</p>
            </div>
            <a href={url} className="resource__btn" target="_blank">
              {fileButtonHandler(resourceType)}
            </a>
          </div>
        )}
        <Comments website-id={HYVOR_WEBSITE_ID} page-id={id} {...constructHyvorSso()} />
      </div>
    </>
  );
};

export default ResourceDetail;
