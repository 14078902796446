import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from 'services/forgotPassword';
import { ResetPasswordForm } from 'common/components/Login/ResetPasswordForm';
import video from 'assets/videos/1-Login-1920x1080.mp4';
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_CONFIG } from 'common/constants/toast';

// TODO replace query-string library with useParams hook
const queryString = require('query-string');

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('forgotPassword');
  const { t: gt } = useTranslation('global');

  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();

  let email, token;

  const RESET_PASSWORD_ROOT = '/reset-password';

  const handleResetPassword = async params => {
    let data = { ...params, email, token };
    try {
      setLoading(true);
      await resetPassword(data);
      setLoading(false);
      toast.success(t('passwordUpdated'), TOAST_CONFIG);
      window.location = '/';
    } catch (err) {
      setLoading(false);
      toast.error(gt('errorMessage'), TOAST_CONFIG);
    }
  };

  useEffect(() => {
    if (!email || !token) {
      navigate('/login');
    }
    if (pathname === RESET_PASSWORD_ROOT) localStorage.removeItem('token');

    document.title = 'Crowe Global - Reset Password';
  }, []);

  try {
    const parsed = queryString.parse(location.search);
    email = parsed.email;
    token = parsed.token;
  } catch (err) {
    return err;
  }

  return (
    <>
      <video className="login__video" autoPlay muted loop webkit-playsinline="true" playsInline>
        <source src={video} type="video/mp4" />
      </video>
      <div className="forgot__container">
        <h2 className="forgot__title">{t('resetPasswordTitle')}</h2>
        <p className="forgot__subtitle"> {t('enterPassword', { email })}</p>
        <ResetPasswordForm
          handleResetPassword={handleResetPassword}
          t={t}
          initialValues={{ token: token }}
          loading={loading}
        />
        <Link to="/login" className="forgot__link">
          {t('backToLogin')}
        </Link>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
