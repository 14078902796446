import { useTranslation } from 'react-i18next';
import { getCurrentYear } from 'common/helpers/date';
import { Link } from 'react-router-dom';
import { termsPath } from 'common/helpers/id';

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <div className="footer__container">
      &copy;
      {` ${getCurrentYear()} Crowe Global`}
      <span className="footer__separator">•</span>
      <Link className="footer__link" to={termsPath}>
        {t('terms')}
      </Link>
    </div>
  );
};

export default Footer;
