import { useLocation, useNavigate } from 'react-router-dom';
import { directoryFirmsPath, directoryMembersPath, directoryDesksPath } from 'common/helpers/path';

const DirectoryHeader = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  return (
    <div className="page__tabs">
      <button
        className={`page__tab ${!pathname.includes('member') ? 'page__tab--active' : ''}`}
        onClick={() => {
          navigate(directoryFirmsPath);
        }}
      >
        Member Firms
      </button>
      <button
        className={`page__tab ${
          pathname.includes('users') || pathname.includes('members') ? 'page__tab--active' : ''
        }`}
        onClick={() => {
          navigate(directoryMembersPath);
        }}
      >
        People
      </button>
      <button
        className={`page__tab ${pathname.includes('desks') ? 'page__tab--active' : ''}`}
        onClick={() => {
          navigate(directoryDesksPath);
        }}
      >
        Foreign Desks
      </button>
    </div>
  );
};

export default DirectoryHeader;
