import { useTranslation } from 'react-i18next';
import Pager from 'common/components/Pager/Pager';
import NoResults from 'common/components/NoResults/NoResults';
import FirmStaff from 'common/components/FirmDetail/FirmStaff';
import { getUserId } from 'common/utils/localStorage';

const MemberList = ({
  isFetching,
  countLabelKey,
  recordCount,
  noResultsIcon,
  members,
  offset,
  pageSize,
  onSelectPage,
  skeleton,
  ...props
}) => {
  const { t } = useTranslation('directory');

  const Skeleton = skeleton;
  return (
    <div>
      {isFetching ? (
        <div className="directory__list mt-20px">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <>
          <div>
            {recordCount ? (
              <FirmStaff staff={members.filter(s => s.user_id !== getUserId())} {...props} />
            ) : (
              <NoResults
                icon="icon-user"
                title={t('noMatchesFound')}
                comment={t('tryDifferentFilters')}
              />
            )}
          </div>
          <Pager total={recordCount} offset={offset} limit={pageSize} onSelectPage={onSelectPage} />
        </>
      )}
    </div>
  );
};

export default MemberList;
