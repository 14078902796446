export const removeDuplicates = values => {
  const uniquesArray = values.reduce((values, currentValue) => {
    const isDuplicated = values.some(item => item.value === currentValue.value);

    if (!isDuplicated) {
      return [...values, currentValue];
    }
    return [...values];
  }, []);

  return uniquesArray;
};
