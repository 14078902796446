import { useState, useEffect, useMemo, useRef } from 'react';
import { useStore } from 'context/Store';
import Select from 'react-select';
import { removeDuplicates } from 'common/helpers/unique';
import { groupByDesk } from 'common/helpers/desks';
import { useTranslation } from 'react-i18next';
import { sortObjArrByAlpha } from 'common/helpers/sorter';

const DesksFilters = ({ items, setFilteredItems }) => {
  const [isOpen, setIsOpen] = useState(true);

  const [state, dispatch] = useStore();
  const { desksFilters } = state;

  const { t } = useTranslation('filters');

  const selectDesksRef = useRef(null);
  const selectCountryRef = useRef(null);

  const clearValues = () => {
    selectDesksRef.current.clearValue();
    selectCountryRef.current.clearValue();
    dispatch({ type: 'SET_DESKS_FILTERS', payload: {} });
  };

  const toggleFilters = () => {
    setIsOpen(!isOpen);
  };

  const desksOptions = useMemo(() => {
    return (
      items &&
      sortObjArrByAlpha(
        removeDuplicates(
          items.flatMap(user =>
            user.languages.map(desk => ({
              label: desk,
              value: desk,
            }))
          )
        ),
        'label'
      )
    );
  }, [items]);

  const countryOptions = useMemo(() => {
    return (
      items &&
      sortObjArrByAlpha(
        removeDuplicates(items.map(user => ({ label: user.country, value: user.country }))),
        'label'
      )
    );
  }, [items]);

  const handleChange = (name, e) => {
    let newValue = e ? e.value : '';
    return dispatch({ type: 'SET_DESKS_FILTERS', payload: { ...desksFilters, [name]: newValue } });
  };

  const handleFilters = () => {
    let data = items?.length ? [...items] : [];
    if (desksFilters.desk) {
      data = [...data.filter(user => user.languages.includes(desksFilters.desk))].map(user => ({
        ...user,
        languages: [desksFilters.desk],
      }));
    }
    if (desksFilters.country) {
      data = data.filter(user => user.country === desksFilters.country);
    }
    setFilteredItems(groupByDesk(data));
  };

  useEffect(() => {
    handleFilters();
  }, [desksFilters, items]);

  return (
    <div className="mt-20px">
      <div className="collapsible__container" onClick={toggleFilters}>
        <p className="collapsible__text">Find a Foreign Desk</p>
        <i className={`fas fa-angle-${isOpen ? 'up' : 'down'} collapsible__icon`} />
      </div>
      <div className={`collapsible__filters ${isOpen ? '' : 'collapsible__filters--close'}`}>
        <label className="filters__label">{t('filterBy')}:</label>
        <div className="collapsible__inputs">
          <Select
            ref={selectDesksRef}
            options={desksOptions}
            onChange={e => handleChange('desk', e)}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Foreign Desk"
            isClearable
            defaultValue={
              desksFilters.desk ? { value: desksFilters.desk, label: desksFilters.desk } : null
            }
          />

          <Select
            ref={selectCountryRef}
            options={countryOptions}
            onChange={e => handleChange('country', e)}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Country"
            isClearable
            defaultValue={
              desksFilters.country
                ? { value: desksFilters.country, label: desksFilters.country }
                : null
            }
          />
          <div className="filters__btn-container">
            <button className="btn btn-primary filters__btn w-125px" onClick={clearValues}>
              <small>{t('clearFilters')}</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesksFilters;
