/* global window */

const localStorage = window.localStorage;

const setItem = (storage, key, value) => {
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (e) {
    return e;
  }
};

const getItem = (storage, key) => {
  try {
    const item = storage.getItem(key);
    if (item === null) {
      return undefined;
    }
    return JSON.parse(item);
  } catch (err) {
    return undefined;
  }
};

const removeItem = (storage, key) => {
  try {
    storage.removeItem(key);
  } catch (e) {
    return e;
  }
};

export function setToken(token) {
  setItem(localStorage, 'token', token);
}

export function getToken() {
  return getItem(localStorage, 'token');
}

export function removeToken() {
  removeItem(localStorage, 'token');
}

export function removeAdminTab() {
  removeItem(localStorage, 'adminTab');
}

export function setFirmId(firmId) {
  setItem(localStorage, 'firmId', firmId);
}

export function getFirmId() {
  return getItem(localStorage, 'firmId');
}

export function removeFirmId() {
  removeItem(localStorage, 'firmId');
}

export function setUserId(userId) {
  setItem(localStorage, 'userId', userId);
}

export function getUserId() {
  return getItem(localStorage, 'userId');
}

export function removeUserId() {
  removeItem(localStorage, 'userId');
}

export function setHyvorHash(hyvorHash) {
  setItem(localStorage, 'hyvorHash', hyvorHash);
}

export function getHyvorHash() {
  return getItem(localStorage, 'hyvorHash');
}

export function setHyvorUserData(hyvorUserData) {
  setItem(localStorage, 'hyvorUserData', hyvorUserData);
}

export function getHyvorUserData() {
  return getItem(localStorage, 'hyvorUserData');
}

export function removeHyvor() {
  removeItem(localStorage, 'hyvorHash');
  removeItem(localStorage, 'hyvorUserData');
}

export function setCookie(cname, cvalue, exdays) {
  let domain = '';
  if (window.location.hostname !== 'localhost') {
    domain = 'domain=crowe.org';
  }

  if (exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    return (document.cookie = cname + '=' + cvalue + ';' + domain + ';' + expires + ';path=/');
  }

  return (document.cookie = cname + '=' + cvalue + ';' + ';path=/');
}

export const setCookieMessage = (name, value, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  return (document.cookie = name + '=' + value + ';' + expires + ';path=/');
};

export const removeCookie = name => {
  let domain = '';
  if (window.location.hostname !== 'localhost') {
    domain = 'domain=crowe.org';
  }

  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${domain}; path=/;`;
};

export const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
