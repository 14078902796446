import { useState, useEffect } from 'react';

export const useGetById = (func, id) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleRequest = async () => {
    try {
      setIsLoading(true);
      setData(await func(id));
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    handleRequest();
  }, [id]);

  return { data, isLoading, isSuccess };
};
