import Flag from 'common/components/Flag/Flag';
import { Link } from 'react-router-dom';
import { foreignDesks } from 'common/constants/foreignDesks';

const DeskCard = ({ id, desk, country, countryCode, firm, first_name, last_name, email }) => {
  return (
    <div className="desks__card">
      <div className="desks__col-1 row-center">
        <div className="card__flag--small">
          <Flag country={foreignDesks[desk]} />
        </div>
        <p className="desks__language">{desk}</p>
      </div>
      <div className="desks__col-2">
        <div className="row-center">
          <div className="card__flag--small row-center">
            <Flag country={countryCode} />
          </div>
          <p className="my-0 mx-6px fw-600">{country}</p>
        </div>
        <p className="desks__firm">{firm}</p>
      </div>
      <div className="desks__col-3">
        <Link to={`/directory/members/${id}`}>{`${first_name} ${last_name}`}</Link>
        <a className="ellipsis-sl" href={`mailto:${email}`}>
          {email}
        </a>
      </div>
    </div>
  );
};

export default DeskCard;
