import { useParams } from 'react-router-dom';
import { getCommitteesById } from 'services/committees';
import CommitteeList from 'common/components/Layout/CommitteeList';
import Spinner from 'common/components/Spinner/Spinner';
import { useGetById } from 'hooks/useGetById';

const CommitteePage = () => {
  const { slug } = useParams();

  const { data: committees, isLoading } = useGetById(getCommitteesById, slug);

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        !!committees?.length &&
        committees.map(committeeList => (
          <CommitteeList key={committeeList.sys.id} committeeList={committeeList} />
        ))
      )}
    </div>
  );
};

export default CommitteePage;
