// Flag helper
export const foreignDesks = {
  china: 'cn',
  french: 'fr',
  singapore: 'sg',
  japan: 'jp',
  korean: 'kr',
  spanish: 'es',
  german: 'de',
};

// FD options for dropdowns
export const languageOptions = [
  { label: 'China', value: 'China' },
  { label: 'French', value: 'French' },
  { label: 'German', value: 'German' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Spanish', value: 'Spanish' },
];

export const FOREIGN_DESK_REPRESENTATIVE_ID = 17;
