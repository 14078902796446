import { useEffect, useState, Fragment } from 'react';
import { useStore } from 'context/Store';
import Select from 'react-select';
import { requiredPicture, isValidLinkedIn, fileSize } from 'common/utils/validations';
import Help from 'common/components/Registration/Help';
import { languageOptions } from 'common/constants/foreignDesks';
import { Controller } from 'react-hook-form';
import { FOREIGN_DESK_REPRESENTATIVE_ID } from 'common/constants/foreignDesks';
import { useTranslation } from 'react-i18next';

const ProfileInfo = ({
  isForeignDesk,
  setIsForeignDesk,
  register,
  trigger,
  watch,
  control,
  errors,
  setValue,
  handleTab,
}) => {
  const [networkRoles, setNetworkRoles] = useState([]);
  const [firmRoles, setFirmRoles] = useState([]);
  const [preview, setPreview] = useState('');

  const [state] = useStore();
  const { network_roles: storedNetworkRoles, firm_roles: storedFirmRoles } = state;

  const { t } = useTranslation('registration');
  const { t: vt } = useTranslation('validations');

  const handleRoles = async () => {
    setNetworkRoles(storedNetworkRoles.sort((a, b) => a.sort_order - b.sort_order));
    setFirmRoles(storedFirmRoles.sort((a, b) => a.sort_order - b.sort_order));
  };

  const profile_photo_url = watch('userData.profile_photo_url');
  const cv_url = watch('userData.cv_url');
  const lang = watch('userData.lang');
  const networkRole = watch('professionalData.networkRole');

  const isValid =
    !!profile_photo_url &&
    ((networkRole[FOREIGN_DESK_REPRESENTATIVE_ID] && !!lang) ||
      !networkRole[FOREIGN_DESK_REPRESENTATIVE_ID]) &&
    !errors.userData?.linkedin &&
    !errors.userData?.profile_photo_url &&
    !errors.userData?.cv_url;

  const handlePreview = () => {
    if (profile_photo_url?.length) {
      if (typeof profile_photo_url === 'string') {
        return setPreview(profile_photo_url);
      }
      if (typeof profile_photo_url === 'object' && profile_photo_url) {
        return setPreview(URL.createObjectURL(profile_photo_url[0]));
      }
    }
    return setPreview('');
  };

  useEffect(() => {
    handleRoles();
  }, [storedNetworkRoles, storedFirmRoles]);

  useEffect(() => {
    handlePreview();
  }, [profile_photo_url]);

  return (
    <div className="profile__form">
      <h3 className="profile__title">{t('about')}</h3>
      <div className="profile__about">
        <div className="profile__files">
          <div className="profile__actions">
            <div className="user__input--photo">
              <div className="profile__img-container">
                {preview && (
                  <img src={preview} alt="Your profile picture" className="profile__img" />
                )}
              </div>
              <div className="user__input--photo-action">
                <label className="profile__btn mb-20px" htmlFor="profile_photo_url">
                  {preview ? t('updatePhoto') : `${t('uploadPhoto')}`}
                </label>
                <span className="input__error--photo">
                  {errors.userData?.profile_photo_url?.message}
                </span>
              </div>
              <input
                {...register('userData.profile_photo_url', {
                  validate: {
                    requiredPicture: value => requiredPicture(value, vt),
                    fileSize: value => fileSize(value, vt, 'image'),
                  },
                })}
                className="hidden"
                name="userData.profile_photo_url"
                id="profile_photo_url"
                type="file"
                accept=".jpg, .jpeg, .png"
              />
            </div>

            <div className="profile__btn--cv">
              {cv_url ? (
                <button
                  className="profile__btn"
                  onClick={() => {
                    setValue('userData.cv_url', null);
                    trigger('userData.cv_url');
                  }}
                >
                  {t('removeCV')}
                </button>
              ) : (
                <label htmlFor="cv_url" className="profile__btn">
                  {t('uploadCV')}
                </label>
              )}

              <input
                {...register('userData.cv_url', {
                  validate: { fileSize: value => fileSize(value, vt, 'CV') },
                })}
                name="userData.cv_url"
                id="cv_url"
                type="file"
                className="hidden"
                accept=".csv, .txt, .pdf, .doc, .docx"
              />
              <span className="input__error">{errors.userData?.cv_url?.message}</span>
              {cv_url?.length ? (
                <div className="profile__file">
                  <i className="fas fa-file-alt admin__icon--file" />
                  <p className="profile__file-name">{cv_url[0].name}</p>
                </div>
              ) : null}
            </div>
            <div className="profile__tip">
              <Help text="File size is limited to 2MB" icon="fas fa-exclamation-circle" />
            </div>
          </div>
        </div>
        <div className="profile__linkedIn ">
          <label>
            <i className="fab fa-linkedin profile__icon profile__icon--linkedIn" />
            LinkedIn
          </label>
          <input
            {...register('userData.linkedin', {
              validate: { isValidLinkedIn: url => isValidLinkedIn(url, vt) },
            })}
            name="userData.linkedin"
            placeholder="LinkedIn Account"
            className="form-control input__container"
          />
          <span className="input__error">{errors.userData?.linkedin?.message}</span>
        </div>
      </div>
      <label className="profile__label--bio">{t('bio')}</label>
      <textarea
        {...register('userData.bio')}
        name="userData.bio"
        placeholder={t('share')}
        className="profile__textarea form-control input__container"
      />
      <h3 className="profile__subtitle mb-10px">{t('networkRole')}</h3>

      <div className="profile__warning">
        <i className="fas fa-info-circle profile__tooltip mt4px" />

        <div className="d-flex-col">
          <span>{t('specialRoles')}</span>
          <span>
            <a
              className="registration__link"
              href="mailto:directory@crowe.org?Subject=MP, ILP registration"
              target="_top"
            >
              directory@crowe.org.
            </a>
          </span>
        </div>
      </div>
      <div className="d-flex-col mb-10px">
        <span>{t('networkComment1')}</span>
      </div>
      <div className="d-flex-col mb-30px">
        <span className="fw-bold">{t('networkComment2')}</span>
      </div>
      <div className="profile__roles">
        {networkRoles.length
          ? networkRoles.map(role => {
              if (role.visible) {
                return (
                  <Fragment key={role.id}>
                    <div className="user__item">
                      <input
                        {...register(`professionalData.networkRole.${role.id}`)}
                        name={`professionalData.networkRole.${role.id}`}
                        type="checkbox"
                        disabled={!role.editable}
                        className="profile__role"
                        onChange={
                          role.name === 'Foreign Desk Representative'
                            ? e => {
                                setIsForeignDesk(!isForeignDesk);
                                if (!e.target.checked) {
                                  setValue('userData.lang', '');
                                }
                              }
                            : null
                        }
                      />
                      <label className={`${!role.editable ? 'profile__role--disabled' : ''}`}>
                        {role.name}
                      </label>
                    </div>
                    {role.name === 'Foreign Desk Representative' && isForeignDesk && (
                      <div className="user__languages">
                        <label>{t('selectLang')}</label>
                        <Controller
                          name="userData.lang"
                          control={control}
                          rules={{ required: 'Required' }}
                          render={({ field, ref }) => (
                            <Select
                              ref={ref}
                              options={languageOptions}
                              className="react-select-container"
                              classNamePrefix={!lang ? 'react-select-error' : 'react-select'}
                              isMulti
                              defaultValue={
                                lang &&
                                lang
                                  .split(',')
                                  .map(language => ({ label: language, value: language }))
                              }
                              onChange={val => {
                                field.onChange(val.map(option => option.value).join(','));
                              }}
                            />
                          )}
                        />
                        {!lang && <span className="input__error">{t('required')}</span>}
                      </div>
                    )}
                  </Fragment>
                );
              }
            })
          : null}
      </div>
      <p className="profile__subtitle mt-20px">
        {t('firmRole')}
        <span className="profile__subtitle profile__subtitle--light">{t('yourRole')}</span>
      </p>
      <div className="profile__roles">
        {!!firmRoles.length &&
          firmRoles.map(role => {
            return (
              <div className="user__item" key={role.id}>
                <input
                  {...register(`professionalData.firmRole.${role.id}`)}
                  name={`professionalData.firmRole.${role.id}`}
                  type="checkbox"
                  label={<label>{role.name}</label>}
                  className="profile__role"
                />
                <label>{role.name}</label>
              </div>
            );
          })}
      </div>
      <div className="profile__footer">
        <Help
          text={t('problem')}
          link="mailto:directory@crowe.org?Subject=Web Portal Problems"
          icon="fas fa-question-circle"
        />

        <div className="profile__btns">
          <button
            className="registration__btn--disabled registration__btn--previous"
            onClick={() => handleTab(1)}
          >
            {t('previous')}
          </button>
          <button
            className={`registration__btn ${
              !isValid ? 'registration__btn--disabled' : 'registration__btn--enabled'
            }`}
            onClick={() => handleTab(3)}
            disabled={!isValid}
          >
            {t('next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
