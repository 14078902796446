import { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'context/Store';
import _ from 'lodash';
import Flag from 'common/components/Flag/Flag';
import { Link } from 'react-router-dom';
import { longIdToTitleHandler } from 'common/utils/role';
import { getCountryById } from 'services/regions';
import { multiCapitalize } from 'common/utils/strings';
import NoResults from 'common/components/NoResults/NoResults';

const MemberDetail = ({ user, firmData }) => {
  const {
    bio,
    certifications,
    industries,
    specialties,
    network_roles,
    profile_photo_url,
    first_name,
    last_name,
    salutation,
    email,
    cv_url,
    firm,
    firm_roles,
    country,
    country_code,
    office_city,
    office_address,
    office_care_of,
    office_state_or_province,
    office_country_id,
    office_direct_phone,
    office_direct_phone_country_code,
    office_phone_number_country_code,
    office_phone_number,
    office_postal_code,
    mobile_phone,
    mobile_phone_country_code,
    lang,
  } = user;

  const [officeCountry, setOfficeCountry] = useState('');

  const [state] = useStore();

  const { t } = useTranslation('userProfile');

  const {
    network_roles: storedNetworkRoles,
    firm_roles: storedFirmRoles,
    industries: storedIndustries,
    specialties: storedSpecialties,
    certifications: storedCertifications,
    hasAdminPermission,
  } = state;

  const handleOfficeCountry = async () => {
    let countryData = await getCountryById(office_country_id);
    setOfficeCountry(countryData.name);
  };

  const sections = [
    {
      title: t('certifications'),
      content: longIdToTitleHandler(certifications, storedCertifications),
      id: 1,
    },
    {
      title: t('networkRole'),
      content: longIdToTitleHandler(network_roles, storedNetworkRoles),
      id: 2,
    },
    {
      title: t('firmRole'),
      content: longIdToTitleHandler(firm_roles, storedFirmRoles),
      id: 3,
    },
    {
      title: t('industryFocus'),
      content: longIdToTitleHandler(industries, storedIndustries),
      id: 4,
    },
    {
      title: t('specialtyPractice'),
      content: longIdToTitleHandler(specialties, storedSpecialties),
      id: 5,
    },
  ];

  const languages = lang?.split(',');

  useEffect(() => {
    if (office_country_id) {
      handleOfficeCountry();
    }
  }, [office_country_id]);

  return (
    <>
      {user.status && user.status !== 'APPROVED' && !hasAdminPermission ? (
        <div className="detail__widget-container--large">
          <NoResults icon="icon-user" title={'This user has been deactivated'} />
        </div>
      ) : (
        <div className="detail__widgets">
          <div className="detail__widget-container--large">
            <div className="widget-todolist rounded position-relative pb-10px">
              <div className="detail__user-info">
                {profile_photo_url ? (
                  <div className="col-md-2 detail__img">
                    <img src={profile_photo_url} alt={`${first_name} ${last_name}`} />
                  </div>
                ) : (
                  <i className="fas fa-user-circle text-secondary detail__icon--user" />
                )}
                <div className="detail__user-name">
                  {first_name && (
                    <h1 className="fs-20px fw-700 m-0 ellipsis-sl">
                      {multiCapitalize(`${salutation ? salutation + ' ' : ''}
                  ${first_name} ${last_name}`)}
                    </h1>
                  )}

                  <div className="row-center">
                    <i className="far fa-envelope fs-16px detail__icon--globe" />
                    <a
                      className="mx-6px fs-16px detail__contact ellipsis-sl"
                      href={`mailto:${email}`}
                    >
                      {email}
                    </a>
                  </div>
                </div>
                {cv_url && (
                  <div className="d-flex justify-content-center col-md-3">
                    <a className="btn btn-primary detail__btn" href={cv_url} download>
                      Download CV
                    </a>
                  </div>
                )}
              </div>

              <div className="d-flex-col">
                {bio && (
                  <div className="d-flex-col m-20px">
                    <h3 className="fs-18px">{t('about')}</h3>
                    <p className="fs-16px mb-20px">{bio}</p>
                  </div>
                )}
                {sections.map(section => (
                  <Fragment key={section.id}>
                    {!!section?.content?.length && (
                      <div className={`d-flex-col mx-20px my-10px border-top pt-10px`}>
                        <h3 className="fs-18px">{section.title}</h3>
                        <ul className="card__roles">
                          {section.content.map(item => (
                            <>
                              <li key={item} className="card__item">
                                {item}
                              </li>
                              {item === 'Foreign Desk Representative' && (
                                <ul className="card__roles">
                                  {languages?.map(language => (
                                    <li className="card__language" key={language}>
                                      {language}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="detail__widget-container--small">
            <div className="widget-todolist rounded position-relative detail__widget">
              <div className="d-flex align-items-start mb-10px">
                {country_code && (
                  <div className="card__flag">
                    <Flag country={country_code} />
                  </div>
                )}
                <div className="d-flex-col">
                  <p className="fs-16px my-0 mx-10px fw-700 lh-1">{country}</p>
                  {firmData?.firm?.id && (
                    <Link
                      to={`/directory/firms/${firmData.firm.id}`}
                      className="mx-10px my-0 detail__contact"
                    >
                      {firm}
                    </Link>
                  )}
                </div>
              </div>

              <div>
                <h3 className="fs-18px fw-700">{t('office')}</h3>
                <p className="detail__office">{office_address}</p>
                <p className="detail__office">{firm}</p>
                <p className="detail__office">
                  {office_care_of ? 'c/o ' : ''}
                  {office_city}
                </p>
                <p className="detail__office">{office_state_or_province}</p>
                <p className="detail__office">{office_postal_code}</p>
                <p className="detail__office">{officeCountry}</p>
              </div>

              <div className="row-center mt-10px pt-10px border-top">
                <i className="fas fa-phone detail__icon--phone" />
                <p className="detail__office">
                  {t('office')}: {office_phone_number_country_code} {office_phone_number}
                </p>
              </div>

              {office_direct_phone && (
                <div className="row-center pt-10px">
                  <i className="fas fa-phone detail__icon--phone" />
                  <p className="detail__office">
                    {t('direct')}: {office_direct_phone_country_code} {office_direct_phone}
                  </p>
                </div>
              )}
              {mobile_phone && (
                <div className="d-flex mt-10px">
                  <i className="fas fa-mobile-alt detail__icon--mobile" />
                  <p className="fs-16px my-0 mx-4px lh-1">
                    {t('mobile')}: {mobile_phone_country_code} {mobile_phone}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MemberDetail;
