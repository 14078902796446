import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from 'assets/crowe-logo.svg';
import { useStore } from 'context/Store';
import { logout } from 'services/login';
import { multiCapitalize } from 'common/utils/strings';
import Search from './Search';

const Header = ({ isAuthenticated, open, setOpen }) => {
  const [role, setRole] = useState('');

  const [state] = useStore();

  const { hasAdminPermission, hasFirmAdminPermission } = state;

  const { currentUser: user } = state;

  const { t } = useTranslation('header');

  const handleRole = () => {
    if (user && user.role) {
      if (user.role === 'FIRM_ADMIN') {
        setRole('Firm Admin');
      } else if (user.role === 'ADMIN') {
        setRole('Crowe Global Admin');
      } else {
        setRole('Member');
      }
    }
  };

  useEffect(() => {
    handleRole();
  }, [user]);

  return (
    <>
      {isAuthenticated && (
        <div id="header" className="app-header position-fixed header__container">
          <div className="navbar-header">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="crowe" className="header__logo" />
            </Link>
            {isAuthenticated ? (
              <div className="header__inner-wrapper">
                {user && (
                  <div className="navbar-item navbar-user dropdown header__dropdown">
                    <a
                      href=""
                      className="navbar-link dropdown-toggle d-flex align-items-center p-0"
                      data-bs-toggle="dropdown"
                    >
                      <div className="header__picture">
                        {user.profile_photo_url ? (
                          <img src={user.profile_photo_url} alt="user" />
                        ) : (
                          <i className="fas fa-user-circle header__no-picture" />
                        )}
                      </div>
                      <div className="header__actions">
                        <div className="header__user">
                          {user.first_name && (
                            <span className="header__name">
                              {multiCapitalize(user.first_name + ' ' + user.last_name)}
                            </span>
                          )}
                          <span className="header__role">{role}</span>
                        </div>
                        <b className="caret" />
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end me-1">
                      <Link to={`/users/${user.id}/edit`} className="dropdown-item header__action">
                        <i className="fas fa-pencil-alt header__icon" />
                        <p className="header__link">{t('editProfile')}</p>
                      </Link>

                      <Link
                        to={`/directory/firms/${user.firm_id}`}
                        className="dropdown-item header__action"
                      >
                        <i className="fas fa-info-circle header__icon" />
                        <p className="header__link">{t('viewMyFirm')}</p>
                      </Link>
                      {!hasAdminPermission && hasFirmAdminPermission && (
                        <Link to="/admin" className="dropdown-item header__action">
                          <i className="fas fa-cog header__icon" />
                          <p className="header__link"> {t('manageMyFirm')}</p>
                        </Link>
                      )}
                      {hasAdminPermission && (
                        <Link to="/admin" className="dropdown-item header__action">
                          <i className="fas fa-cog header__icon" />
                          <p className="header__link"> {t('Manage All Firms')}</p>
                        </Link>
                      )}
                      <div className="dropdown-divider" />
                      <a className="dropdown-item header__action" onClick={logout}>
                        <i className="fas fa-power-off header__icon" />
                        <p className="header__link"> Log Out </p>
                      </a>
                    </div>
                  </div>
                )}
                <Search />
              </div>
            ) : (
              <Link to="/login"> Login</Link>
            )}
            <button
              type="button"
              className="navbar-mobile-toggler"
              data-toggle="app-sidebar-mobile"
              onClick={() => setOpen(!open)}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
