const FirmCardSkeleton = () => {
  return (
    <div className="skeleton__firm-card is-loading">
      <div className="skeleton__columns">
        <div className="skeleton__column">
          <h2 className="skeleton__title--centered"></h2>
        </div>
        <div className="skeleton__column">
          <h2></h2>
          <p></p>
        </div>
        <div className="skeleton__column">
          <h2 className="skeleton__title--centered"></h2>
        </div>
      </div>
    </div>
  );
};

export default FirmCardSkeleton;
