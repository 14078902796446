import { useState, useEffect } from 'react';
import { getEvents, getPastEvents } from 'services/events';
import { getSectionById } from 'services/navigation';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EventsList from 'common/components/Events/EventsList';
import Page from 'common/components/Layout/Page';
import EventsFilters from 'common/components/Events/EventsFilters';
import { eventsSectionSlug, eventsSectionId } from 'common/helpers/id';
import { useGet } from 'hooks/useGet';
import { Link } from 'react-router-dom';
import { upcomingEventsPath, pastEventsPath } from 'common/helpers/path';
import ArticleSkeleton from 'common/components/Skeletons/ArticleSkeleton';

const Events = () => {
  const [tab, setTab] = useState(0);
  const [pages, setPages] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const { t } = useTranslation('events');

  const location = useLocation();
  const { pathname } = location;

  const fixPages = {
    0: <EventsList filteredEvents={filteredEvents} t={t} setFilteredEvents={setFilteredEvents} />,
    1: <EventsList filteredEvents={filteredEvents} t={t} setFilteredEvents={setFilteredEvents} />,
  };

  const { data: events, isLoading, isSuccess } = useGet(getEvents) || [];

  const { data: pastEvents, isLoading: isLoadingPastEvents } = useGet(getPastEvents) || [];

  const handleEvents = () => {
    if (pathname.includes(upcomingEventsPath)) {
      setTab(0);
      !!events?.length && setFilteredEvents(events);
    }
    if (pathname.includes(pastEventsPath)) {
      setTab(1);
      !!pastEvents?.length && setFilteredEvents(pastEvents);
    }
  };

  const handlePages = async () => {
    const data = await getSectionById(`.${eventsSectionId}`);
    setPages(
      data.fields.pages.reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value.fields.title]: value.fields.slug,
        };
      }, {})
    );
  };

  const customPages = Object.values(pages).map((id, key) => {
    return { [key + 3]: <Page id={id} /> };
  });

  const contentManager = Object.assign({}, fixPages, ...customPages);

  useEffect(() => {
    handleEvents();
    handlePages();
    document.title = 'Crowe Global - Events';
  }, [events, pastEvents, pathname]);

  return (
    <div className="events__container">
      <div className="page__tabs page__tabs--responsive">
        <Link
          to={upcomingEventsPath}
          className={`page__tab ${tab === 0 ? 'page__tab--active' : ''}`}
        >
          {t('upcomingEvents')}
        </Link>
        <Link to={pastEventsPath} className={`page__tab ${tab === 1 ? 'page__tab--active' : ''}`}>
          {t('pastEvents')}
        </Link>
        {Object.keys(pages).map((page, key) => {
          if (page !== 'Upcoming Events' && page != 'Past Events') {
            return (
              <Link
                key={key}
                to={`/${eventsSectionSlug}/pages/${pages[page]}`}
                className={`page__tab ${tab === key + 3 ? 'page__tab--active' : ''}`}
              >
                {page}
              </Link>
            );
          }
        })}
      </div>
      {(tab === 0 || tab === 1) && (
        <EventsFilters
          events={!tab ? events : pastEvents}
          setFilteredEvents={setFilteredEvents}
          tab={tab}
        />
      )}
      {isLoading && isLoadingPastEvents ? (
        <div className="row__skeletons">
          <ArticleSkeleton />
          <ArticleSkeleton />
          <ArticleSkeleton />
          <ArticleSkeleton />
        </div>
      ) : (
        contentManager[tab]
      )}
    </div>
  );
};

export default Events;
