import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FirmDirectory from 'core/Directory/FirmDirectory';
import MemberDirectory from 'core/Directory/MemberDirectory';
import ForeignDesks from 'core/Directory/ForeignDesks';
import { directoryFirmsPath, directoryMembersPath, directoryDesksPath } from 'common/helpers/path';

const Directory = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const contentManager = {
    0: <FirmDirectory />,
    1: <MemberDirectory />,
    2: <ForeignDesks />,
  };

  const tabs = [
    { name: 'Member Firms', link: directoryFirmsPath, id: 0 },
    { name: 'People', link: directoryMembersPath, id: 1 },
    { name: 'Foreign Desks', link: directoryDesksPath, id: 2 },
  ];

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const handleTabs = () => {
    if (pathname === '/directory' || directoryFirmsPath) {
      setSelectedTab(0);
      document.title = 'Crowe Global - GD Member Firms';
    }
    if (pathname === directoryMembersPath) {
      setSelectedTab(1);
      document.title = 'Crowe Global - GD People';
    }
    if (pathname === directoryDesksPath) {
      setSelectedTab(2);
      document.title = 'Crowe Global - GD Foreign Desks';
    }
  };

  const handleClick = (id, link) => {
    setSelectedTab(id);
    navigate(link);
  };

  useEffect(() => {
    handleTabs();
  }, [pathname]);

  return (
    <div className="directory__container">
      <div className="d-flex-col">
        <div className="page__tabs">
          {tabs.map(tab => (
            <button
              key={tab.name}
              className={`page__tab ${selectedTab === tab.id ? 'page__tab--active' : ''}`}
              onClick={() => {
                handleClick(tab.id, tab.link);
              }}
            >
              {tab.name}
            </button>
          ))}
        </div>
        {contentManager[selectedTab]}
      </div>
    </div>
  );
};

export default Directory;
