const Modal = ({ handleClick, children }) => {
  return (
    <section className="modal__bg" onClick={handleClick}>
      <div className="modal__align">
        <div className="modal__content">{children}</div>
      </div>
    </section>
  );
};

export default Modal;