import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Spinner from 'common/components/Spinner/Spinner';
import ResourcesWidget from 'common/components/Home/ResourcesWidget/ResourcesWidget';
import KeyContacts from 'common/components/Home/KeyContacts/KeyContacts';
import Banners from 'common/components/Home/Banners/Banners';
import { Link } from 'react-router-dom';
import { newsSectionPath } from 'common/helpers/id';
import { useWindowSize } from 'hooks/useWindowSize';
import { trainingSection, downloadsSection } from 'common/helpers/id';
import Row from 'common/components/Home/Row';
import { socialsPath } from 'common/helpers/path';
import { useStore } from 'context/Store';

const Home = () => {
  const [state] = useStore();

  const { currentUser: user } = state;

  const { t } = useTranslation('home');

  const { width } = useWindowSize();

  const isBigScreen = width > 1389;

  useEffect(() => {
    document.title = 'Crowe Global - Home';
  }, []);

  return (
    <>
      {!user ? (
        <Spinner />
      ) : (
        <div className="home__container">
          <div>
            <div className="row__container">
              <Banners />
            </div>
            <div className="home__subtitle-container">
              <h2 className="home__subtitle">{t('latestNews')}</h2>
              <span className="home__subtitle-separator">/</span>
              <Link to={newsSectionPath} className="home__subtitle-link">
                {t('viewAllNews')}
              </Link>
            </div>

            <Row tag="news" noTilesText={t('noNews')} />

            <div className="home__subtitle-container">
              <h2 className="home__subtitle">{t('upcomingEvents')}</h2>
              <span className="home__subtitle-separator">/</span>
              <Link to="/events" className="home__subtitle-link">
                {t('viewAllEvents')}
              </Link>
            </div>

            <Row tag="events" noTilesText={t('noEvents')} />
            <div className="home__subtitle-container mt-40px">
              <h2 className="home__subtitle">{t('widgets.socialTitle')}</h2>
              <span className="home__subtitle-separator">/</span>
              <Link to="/social/new-post" className="home__subtitle">
                {`+ ${t('widgets.addPost')}`}
              </Link>
              <span className="home__subtitle-separator">/</span>
              <Link to={socialsPath} className="home__subtitle-link">
                {t('widgets.viewAllPosts')}
              </Link>
            </div>
            <Row tag="social" noTilesText={t('noPosts')} />
            <div className="row__container mt-40px">
              <div className={`home__widget home__widget--${isBigScreen ? '25' : '33'}`}>
                <ResourcesWidget title={t('widgets.downloadsTitle')} link={downloadsSection} />
              </div>
              <div className={`home__widget home__widget--${isBigScreen ? '25' : '33'}`}>
                <ResourcesWidget
                  title={t('widgets.trainingMaterialsTitle')}
                  link={trainingSection}
                  tags={[{ sys: { id: 'training' } }]}
                />
              </div>
              <div className={`home__widget home__widget--${isBigScreen ? '25' : '33'}`}>
                <KeyContacts title={t('widgets.CGContacts')} link="/directory/firms/232" tags="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
