import { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import PendingUser from 'common/components/Users/PendingUser';
import NoResults from 'common/components/NoResults/NoResults';

const PendingUsers = ({
  users,
  handleApprove,
  handleReject,
  handleOptionsClick,
  handleSendPasswordResetEmail,
}) => {
  const [selectedUserId, setSelectedUserId] = useState(null);

  const { t } = useTranslation('directory');

  return (
    <>
      {users.length ? (
        <div className="admin__tab-container rounded w-100">
          <div className="pending__header border-bottom">
            <span className="pending__title">{t('name')}</span>
            <span className="pending__title">{t('networkRole')}</span>
            <span className="pending__title">{t('firmRole')}</span>
            <span className="pending__title">{t('city')}</span>
          </div>
          {users.map(u => {
            return (
              <PendingUser
                user={u}
                key={u.id}
                approve={handleApprove}
                reject={handleReject}
                optionsClick={handleOptionsClick}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                handleSendPasswordResetEmail={handleSendPasswordResetEmail}
                t={t}
              />
            );
          })}
        </div>
      ) : (
        <NoResults icon="icon-user" title="No pending users" comment="" />
      )}
    </>
  );
};

export default PendingUsers;
