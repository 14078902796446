import { useMemo } from 'react';
import { useStore } from 'context/Store';
import { Link } from 'react-router-dom';
import { getUserById } from 'services/user';
import { shortIdToTitleHandler } from 'common/utils/role';
import { useTranslation } from 'react-i18next';
import { useGetById } from 'hooks/useGetById';
import Flag from 'common/components/Flag/Flag';
import ContactCardSkeleton from 'common/components/Skeletons/ContactCardSkeleton';
import { multiCapitalize } from 'common/utils/strings';

const ContactCard = ({ id, customRole, isCommittee }) => {
  const { data: user, isLoading, isSuccess } = useGetById(getUserById, id) || {};

  const [state] = useStore();

  const { network_roles: storedRoles } = state;

  const roles = useMemo(
    () => (user ? shortIdToTitleHandler(user.network_roles, storedRoles) : []),
    [user, storedRoles]
  );

  return (
    <>
      {isLoading ? (
        <ContactCardSkeleton />
      ) : (
        <Link to={`/directory/members/${id}`} className="contact-card__container">
          {user?.profile_photo_url ? (
            <div className="contact-card__img">
              <img src={user?.profile_photo_url} alt={`${user.first_name} ${user.last_name}`} />
            </div>
          ) : (
            <i className="fas fa-user-circle fs-60px text-secondary" />
          )}
          <div className="d-flex-col mx-10px">
            <p className="contact-card__name">
              {multiCapitalize(`${user?.first_name} ${user?.last_name}`)}
            </p>
            {customRole && (
              <li key={customRole} className="card__item">
                {customRole}
              </li>
            )}
            {!!roles?.length &&
              roles.map(role => (
                <li key={role} className="card__item">
                  {role}
                </li>
              ))}
            {user?.country_code && (
              <div className="contact-card__country">
                <div className="contact-card__flag">
                  <Flag country={user.country_code} />
                </div>
                <p className="contact-card__role">{user.country}</p>
              </div>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

export default ContactCard;
