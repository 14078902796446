import { post } from 'common/api';
import axios from 'axios';

export const resetPassword = async data => {
  try {
    await axios.post(`${process.env.CROWE_BASE_SERVER_URL}/auth/reset-password`, data);
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const postEmail = async body => {
  try {
    const res = await post('/auth/forgot-password', body, {}, true);
    return res;
  } catch (err) {
    throw new Error(err);
  }
};
