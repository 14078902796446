import { get } from '../common/api';

export const getTags = async () => {
  try {
    const { items } = await get('/tags');
    return items;
  } catch (error) {
    return error;
  }
};
