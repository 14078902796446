import { default as Slick } from 'react-slick';

const Slider = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="slider__container">
      <Slick {...settings}>
        {items.map(item => (
          <img key={item.sys.id} src={item.fields.file.url} />
        ))}
      </Slick>
    </div>
  );
};

export default Slider;
