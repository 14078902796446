import { useState, useEffect } from 'react';

export const useGet = (func, ...props) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSpecialties = async () => {
    try {
      setIsLoading(true);
      setData(await func(...props));
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    handleSpecialties();
  }, []);

  return { data, isLoading, isSuccess };
};
