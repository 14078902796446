import { get } from '../common/api';

export const getPageById = async slug => {
  try {
    const { items } = await get(`/pages/${slug}`);
    return items;
  } catch (error) {
    return error;
  }
};
