import ListResource from 'common/components/Layout/ListResource';
import { useTranslation } from 'react-i18next';

const ResourcesList = ({ resources }) => {
  const { t } = useTranslation('layouts');

  return (
    <div className="widget-todolist rounded position-relative detail__widget m-0">
      <h3 className="fs-16px fw-700 lh-sm m-0">{t('relatedContent')}</h3>
      {resources.map((resource, key) => (
        <ListResource key={key} resource={resource} index={key} />
      ))}
    </div>
  );
};

export default ResourcesList;
