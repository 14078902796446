import React from 'react';

class Verif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageapi: null,
      messagestat: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    fetch(`${process.env.CROWE_REPORTING_HOST}?user_id=${this.props.user}`)
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        this.setState({ messageapi: data.message, messagestat: data.status });
        //window.location.reload();
      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }

  render() {
    const { messageapi, messagestat, errorMessage } = this.state;
    return 'Verification ' + messageapi;
  }
}

export { Verif };
