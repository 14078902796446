import { useState } from 'react';
import { useStore } from 'context/Store';

export const useFilters = (func, offset, filters) => {
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [state] = useStore();

  const { specialties } = state;

  const handleFilters = async () => {
    try {
      setLoading(true);
      const data = await func(offset, filters, specialties);
      setItems(data);
      setCount(data.count);
      setError(false);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  return { items, count, loading, error, handleFilters };
};
