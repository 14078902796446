const ResourceSkeleton = () => {
  return (
    <div className="resource__container rounded">
      <div className="skeleton__resource is-loading">
        <div className="resource__body">
          <p></p>
          <h2 />
          <p className="m-0" />
          <p className="m-0" />
          <p className="m-0" />
          <div className="skeleton__actions mt-10px">
            <i className="skeleton__icon" />
            <span className="skeleton__button" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceSkeleton;
