import { useState, useEffect } from 'react';

const Flag = ({ country }) => {
  const [flagImage, setFlagImage] = useState('');

  const handleFlag = () => {
    let flag = country ? require(`assets/flags/${country.toLowerCase()}.svg`) : '';
    setFlagImage(flag);
  };

  useEffect(() => {
    handleFlag();
  }, []);

  return <>{country && <img className="flag" src={flagImage} alt={`${country} flag`} />}</>;
};

export default Flag;
